import React, { memo } from 'react';

import FilterByCompany from '../company/filter-by-company/FilterByCompany';

import AppActivityListTableComponent from './AppActivityListTableComponent';

const AppActivityListComponent = memo(props => {
  const {
    formData: { data, total_rows, company_code },
    turnoversLoaded,
    handlePerRowsChange,
    handlePageChange,
    handleSort,
    handleFilter,
  } = props;

  const tableData = { data, total_rows };
  return (
    <div className="turnover-component">
      <>
        <div className="row">
          <div className="col-md-3">
            <FilterByCompany
              company_code={company_code}
              handleFilter={handleFilter}
            />
          </div>
        </div>

        <div className="clear" />
      </>

      <div className="card">
        <AppActivityListTableComponent
          formData={tableData}
          turnoversLoaded={turnoversLoaded}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          handleSort={handleSort}
        />
      </div>
    </div>
  );
});

export default AppActivityListComponent;
