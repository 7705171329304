import {
  GET_ALL_USER_CATEGORY,
  SET_CATEGORY_TO_OPTION,
  DELETE_CATEGORY,
  REMOVE_ALL_CATEGORY,
  REMOVE_CATEGORY_TO_OPTION,
} from '../actions/actionTypes';

const initialState = {
  categories: { loaded: false, data: [], totalRows: 0 },
  categoryToOption: { loaded: false, data: [] },
};

const userCategoryReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_USER_CATEGORY:
      return {
        ...state,
        categories: {
          loaded: true,
          data: payload.categories,
          totalRows: payload.total_rows,
        },
      };
    case SET_CATEGORY_TO_OPTION:
      return { ...state, categoryToOption: { loaded: true, data: payload } };

    case DELETE_CATEGORY:
      let categories = state.categories.data.filter(c => payload !== c._id);
      return {
        ...state,
        categories: {
          loaded: true,
          data: categories,
          totalRows: state.categories.totalRows - 1,
        },
      };

    case REMOVE_ALL_CATEGORY:
      return {
        ...state,
        categories: { loaded: false, data: [], totalRows: 0 },
      };
    case REMOVE_CATEGORY_TO_OPTION:
      return { ...state, categoryToOption: { loaded: false, data: [] } };

    default:
      return state;
  }
};

export default userCategoryReducer;
