import axios from '../axios';
import { CUSTOMER_BE_URL } from '../helpers/api_helper';
import {
  GET_ERRORS,
  GET_APP_ACTIVITY,
  REMOVE_ALL_APPACTIVITY,
} from './actionTypes';

import { hasString, hasNumber } from '../helpers/data_helper';
import { isDataEmpty } from '../helpers/empty_helper';
import { errorHelper } from '../helpers/error_helper';

// get
export const getAppActivity = (
  page,
  pageSize,
  searchTerm = '',
  sortField = '_id',
  sortDir = 1,
  query
) => dispatch => {
  dispatch({ type: REMOVE_ALL_APPACTIVITY });

  axios
    .get(`${CUSTOMER_BE_URL}/accesslog`, {
      params: { page, pageSize, searchTerm, sortField, sortDir, ...query },
    })
    .then(res => {
      let {
        data: { customers, total_rows },
      } = res.data;

      const appActivity = customers.map(t => {
        const agent = !isDataEmpty(t.agent)
          ? { firstname: t.agent.firstname, surname: t.agent.surname }
          : { firstname: '_', surname: '' };

        const dt = new Date();
        dt.setDate(dt.getDate() - 30);
        const last_30_days = t.accesses_list.filter(d => new Date(d) >= dt)
          .length;

        return {
          client_code: hasString(t.code),
          client_name: hasString(t.user_name),
          company_name: !isDataEmpty(t.company) ? t.company.company_name : '_',
          agent_code: hasString(t.agent_code),
          agent_name: agent.firstname + ' ' + agent.surname,
          initial_access: hasString(t.first_access),
          last_access: hasString(t.last_access),
          total_access: hasNumber(t.accesses_list.length),
          month_access: hasNumber(last_30_days),
        };
      });

      dispatch({
        type: GET_APP_ACTIVITY,
        payload: { appActivity, total_rows },
      });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

// remove
export const removeAppActivity = () => dispatch => {
  dispatch({ type: REMOVE_ALL_APPACTIVITY });
};

export const csvDownload = async () => {
  const response = await axios.get(`${CUSTOMER_BE_URL}/accesslist/csv`, {
    responseType: 'blob',
  });
  const url = window.URL.createObjectURL(response.data);
  const tempLink = document.createElement('a');
  tempLink.href = url;
  tempLink.setAttribute('download', 'appactivity.csv');
  tempLink.click();
};
