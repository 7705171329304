export default {
  none: {
    header: {
      self: {},
      items: [],
    },
    aside: {
      seld: {},
      items: [{}],
    },
  },
  company_manager: {
    header: {
      self: {},
      items: [],
    },
    aside: {
      self: {},
      items: [
        {
          title: 'User',
          root: true,
          icon: 'flaticon-users',
          translate: 'MENU.USERS',
          submenu: [
            {
              title: 'Bousers',
              root: true,
              icon: 'flaticon-users',
              page: 'bo-user/list',
              translate: 'MENU.BOUSERS',
            },
            {
              title: 'Appusers',
              root: true,
              icon: 'flaticon-users',
              page: 'app-user/list',
              translate: 'MENU.APPUSERS',
            },
          ],
        },
        {
          title: 'Communication',
          root: true,
          icon: 'flaticon-edit-1',
          translate: 'MENU.COMMUNICATION',
          submenu: [
            {
              title: 'News',
              root: true,
              icon: 'flaticon-edit-1',
              page: 'news/list',
              translate: 'MENU.NEWS',
            },
            {
              title: 'Luigi',
              root: true,
              icon: 'flaticon-edit-1',
              page: 'luigi/list',
              translate: 'MENU.LUIGICOMINIONLUS',
            },
            {
              title: 'Survey',
              root: true,
              icon: 'flaticon-analytics',
              page: 'survey/list',
              translate: 'MENU.SURVEY',
            },
          ],
        },
        {
          title: 'Target',
          root: true,
          icon: 'flaticon-graphic',
          translate: 'MENU.TARGET',
          submenu: [
            {
              title: 'Target and Turnover',
              root: true,
              icon: 'flaticon-graphic-1',
              page: 'target/list',
              translate: 'MENU.TARGET.AND.TURNOVER',
            },
            {
              title: 'Turnover',
              root: true,
              icon: 'flaticon-graphic-1',
              page: 'turnover/list',
              translate: 'MENU.TURNOVERS',
            },
          ],
        },
        {
          title: 'Promotion',
          root: true,
          icon: 'flaticon-presentation-1',
          translate: 'MENU.PROMOTION',
          submenu: [
            {
              title: 'Promotion',
              root: true,
              icon: 'flaticon-presentation-1',
              page: 'promotion/list',
              translate: 'MENU.PROMOTION.MANGEMENT',
            },
            {
              title: 'Company Report',
              page: 'promotion-report/company/list',
              icon: 'flaticon-graphic',
              translate: 'MENU.PROMOTION.COMPANY.REPORT',
            },
            {
              title: 'Agent Report',
              page: 'promotion-report/agent/list',
              icon: 'flaticon-graphic',
              translate: 'MENU.PROMOTION.AGENT.REPORT',
            },
          ],
        },
      ],
    },
  },
  area_manager: {
    header: {
      self: {},
      items: [],
    },
    aside: {
      self: {},
      items: [
        {
          title: 'User',
          root: true,
          icon: 'flaticon-users',
          translate: 'MENU.USERS',
          submenu: [
            {
              title: 'Bousers',
              root: true,
              icon: 'flaticon-users',
              page: 'bo-user/list',
              translate: 'MENU.BOUSERS',
            },
            {
              title: 'Appusers',
              root: true,
              icon: 'flaticon-users',
              page: 'app-user/list',
              translate: 'MENU.APPUSERS',
            },
          ],
        },
        {
          title: 'Communication',
          root: true,
          icon: 'flaticon-edit-1',
          translate: 'MENU.COMMUNICATION',
          submenu: [
            {
              title: 'News',
              root: true,
              icon: 'flaticon-edit-1',
              page: 'news/list',
              translate: 'MENU.NEWS',
            },
            {
              title: 'Luigi',
              root: true,
              icon: 'flaticon-edit-1',
              page: 'luigi/list',
              translate: 'MENU.LUIGICOMINIONLUS',
            },
            {
              title: 'Survey',
              root: true,
              icon: 'flaticon-analytics',
              page: 'survey/list',
              translate: 'MENU.SURVEY',
            },
          ],
        },
        {
          title: 'Target',
          root: true,
          icon: 'flaticon-graphic',
          translate: 'MENU.TARGET',
          submenu: [
            {
              title: 'Target and Turnover',
              root: true,
              icon: 'flaticon-graphic-1',
              page: 'target/list',
              translate: 'MENU.TARGET.AND.TURNOVER',
            },
            {
              title: 'Turnover',
              root: true,
              icon: 'flaticon-graphic-1',
              page: 'turnover/list',
              translate: 'MENU.TURNOVERS',
            },
          ],
        },
        {
          title: 'Promotion',
          root: true,
          icon: 'flaticon-presentation-1',
          translate: 'MENU.PROMOTION',
          submenu: [
            {
              title: 'Promotion',
              root: true,
              icon: 'flaticon-presentation-1',
              page: 'promotion/list',
              translate: 'MENU.PROMOTION.MANGEMENT',
            },
            {
              title: 'Company Report',
              page: 'promotion-report/company/list',
              icon: 'flaticon-graphic',
              translate: 'MENU.PROMOTION.COMPANY.REPORT',
            },
            {
              title: 'Agent Report',
              page: 'promotion-report/agent/list',
              icon: 'flaticon-graphic',
              translate: 'MENU.PROMOTION.AGENT.REPORT',
            },
          ],
        },
      ],
    },
  },
  site_manager: {
    header: {
      self: {},
      items: [],
    },
    aside: {
      self: {},
      items: [
        {
          title: 'User',
          root: true,
          icon: 'flaticon-users',
          translate: 'MENU.USERS',
          submenu: [
            {
              title: 'Bousers',
              root: true,
              icon: 'flaticon-users',
              page: 'bo-user/list',
              translate: 'MENU.BOUSERS',
            },
            {
              title: 'Appusers',
              root: true,
              icon: 'flaticon-users',
              page: 'app-user/list',
              translate: 'MENU.APPUSERS',
            },
          ],
        },
        {
          title: 'Communication',
          root: true,
          icon: 'flaticon-edit-1',
          translate: 'MENU.COMMUNICATION',
          submenu: [
            {
              title: 'News',
              root: true,
              icon: 'flaticon-edit-1',
              page: 'news/list',
              translate: 'MENU.NEWS',
            },
            {
              title: 'Luigi',
              root: true,
              icon: 'flaticon-edit-1',
              page: 'luigi/list',
              translate: 'MENU.LUIGICOMINIONLUS',
            },
            {
              title: 'Survey',
              root: true,
              icon: 'flaticon-analytics',
              page: 'survey/list',
              translate: 'MENU.SURVEY',
            },
          ],
        },
        {
          title: 'Target',
          root: true,
          icon: 'flaticon-graphic',
          translate: 'MENU.TARGET',
          submenu: [
            {
              title: 'Target and Turnover',
              root: true,
              icon: 'flaticon-graphic-1',
              page: 'target/list',
              translate: 'MENU.TARGET.AND.TURNOVER',
            },
            {
              title: 'Turnover',
              root: true,
              icon: 'flaticon-graphic-1',
              page: 'turnover/list',
              translate: 'MENU.TURNOVERS',
            },
          ],
        },
        {
          title: 'Promotion',
          root: true,
          icon: 'flaticon-presentation-1',
          translate: 'MENU.PROMOTION',
          submenu: [
            {
              title: 'Promotion',
              root: true,
              icon: 'flaticon-presentation-1',
              page: 'promotion/list',
              translate: 'MENU.PROMOTION.MANGEMENT',
            },
            {
              title: 'Company Report',
              page: 'promotion-report/company/list',
              icon: 'flaticon-graphic',
              translate: 'MENU.PROMOTION.COMPANY.REPORT',
            },
            {
              title: 'Agent Report',
              page: 'promotion-report/agent/list',
              icon: 'flaticon-graphic',
              translate: 'MENU.PROMOTION.AGENT.REPORT',
            },
          ],
        },
      ],
    },
  },
  agent: {
    header: {
      self: {},
      items: [{}],
    },
    aside: {
      self: {},
      items: [
        {
          title: 'User',
          root: true,
          icon: 'flaticon-users',
          translate: 'MENU.USERS',
          submenu: [
            {
              title: 'Appusers',
              root: true,
              icon: 'flaticon-users',
              page: 'app-user/list',
              translate: 'MENU.APPUSERS',
            },
          ],
        },
        {
          title: 'Target',
          root: true,
          icon: 'flaticon-graphic',
          translate: 'MENU.TARGET',
          submenu: [
            {
              title: 'Target and Turnover',
              root: true,
              icon: 'flaticon-graphic-1',
              page: 'target/list',
              translate: 'MENU.TARGET.AND.TURNOVER',
            },
            {
              title: 'Turnover',
              root: true,
              icon: 'flaticon-graphic-1',
              page: 'turnover/list',
              translate: 'MENU.TURNOVERS',
            },
          ],
        },
        {
          title: 'Promotion',
          root: true,
          icon: 'flaticon-presentation-1',
          translate: 'MENU.PROMOTION',
          submenu: [
            {
              title: 'Promotion',
              root: true,
              icon: 'flaticon-presentation-1',
              page: 'promotion/list',
              translate: 'MENU.PROMOTION.MANGEMENT',
            },
            {
              title: 'Company Report',
              page: 'promotion-report/company/list',
              icon: 'flaticon-graphic',
              translate: 'MENU.PROMOTION.COMPANY.REPORT',
            },
            {
              title: 'Agent Report',
              page: 'promotion-report/agent/list',
              icon: 'flaticon-graphic',
              translate: 'MENU.PROMOTION.AGENT.REPORT',
            },
          ],
        },
      ],
    },
  },
  admin: {
    header: {
      self: {},
      items: [],
    },
    aside: {
      self: {},
      items: [
        {
          title: 'Dashboard',
          root: true,
          icon: 'flaticon-buildings',
          page: 'dashboard',
          translate: 'MENU.DASHBOARD',
        },
        {
          title: 'User',
          root: true,
          icon: 'flaticon-users',
          translate: 'MENU.USERS',
          submenu: [
            {
              title: 'Bousers',
              root: true,
              icon: 'flaticon-users',
              page: 'bo-user/list',
              translate: 'MENU.BOUSERS',
            },
            {
              title: 'Appusers',
              root: true,
              icon: 'flaticon-users',
              page: 'app-user/list',
              translate: 'MENU.APPUSERS',
            },
            {
              title: 'Groups',
              root: true,
              icon: 'flaticon-users',
              page: 'group/list',
              translate: 'MENU.GROUP',
            },
          ],
        },
        {
          title: 'Communication',
          root: true,
          icon: 'flaticon-edit-1',
          translate: 'MENU.COMMUNICATION',
          submenu: [
            {
              title: 'News',
              root: true,
              icon: 'flaticon-edit-1',
              page: 'news/list',
              translate: 'MENU.NEWS',
            },
            {
              title: 'Luigi',
              root: true,
              icon: 'flaticon-edit-1',
              page: 'luigi/list',
              translate: 'MENU.LUIGICOMINIONLUS',
            },
            {
              title: 'Survey',
              root: true,
              icon: 'flaticon-analytics',
              page: 'survey/list',
              translate: 'MENU.SURVEY',
            },
            {
              title: 'Email Templates',
              root: true,
              icon: 'flaticon-email',
              page: 'email-template/list',
              translate: 'MENU.EMAILTEMPLATES',
            },
            {
              title: 'Push Templates',
              root: true,
              icon: 'flaticon-email',
              page: 'push-template/list',
              translate: 'MENU.PUSHTEMPLATES',
            },
            {
              title: 'Custom Email Templates',
              root: true,
              icon: 'flaticon-email',
              page: 'email-template/custom/list',
              translate: 'MENU.CUSTOMEMAILTEMPLATES',
            },
            {
              title: 'Custom Push Templates',
              root: true,
              icon: 'flaticon-email',
              page: 'push-template/custom/list',
              translate: 'MENU.CUSTOMPUSHTEMPLATES',
            },
          ],
        },
        {
          title: 'Target',
          root: true,
          icon: 'flaticon-graphic',
          translate: 'MENU.TARGET',
          submenu: [
            {
              title: 'Journeys',
              root: true,
              icon: 'flaticon-map-location ',
              page: 'journey/list',
              translate: 'MENU.JOURNEYS',
            },
            {
              title: 'Target and Turnover',
              root: true,
              icon: 'flaticon-graphic-1',
              page: 'target/list',
              translate: 'MENU.TARGET.AND.TURNOVER',
            },
            {
              title: 'Turnover',
              root: true,
              icon: 'flaticon-graphic-1',
              page: 'turnover/list',
              translate: 'MENU.TURNOVERS',
            },
          ],
        },
        {
          title: 'Promotion',
          root: true,
          icon: 'flaticon-presentation-1',
          translate: 'MENU.PROMOTION',
          submenu: [
            {
              title: 'Promotion',
              root: true,
              icon: 'flaticon-presentation-1',
              page: 'promotion/list',
              translate: 'MENU.PROMOTION.MANGEMENT',
            },
            // {
            //   title: 'Award Option',
            //   root: true,
            //   icon: 'flaticon-presentation-1',
            //   page: 'promotion/award-option/list',
            //   translate: 'PROMO.AWARD.OPTION',
            // },
            {
              title: 'Company Report',
              page: 'promotion-report/company/list',
              icon: 'flaticon-graphic',
              translate: 'MENU.PROMOTION.COMPANY.REPORT',
            },
            {
              title: 'Agent Report',
              page: 'promotion-report/agent/list',
              icon: 'flaticon-graphic',
              translate: 'MENU.PROMOTION.AGENT.REPORT',
            },
            {
              title: 'User Sales',
              root: true,
              icon: 'flaticon-price-tag',
              page: 'user-sale/list',
              translate: 'MENU.USER.SALES',
            },
          ],
        },
        {
          title: 'Import Csv',
          root: true,
          icon: 'flaticon2-file',
          translate: 'MENU.CSV.IMPORTS',
          submenu: [
            {
              title: 'Company Csv Imports',
              root: true,
              icon: 'flaticon-business',
              page: 'csv/list/Company',
              translate: 'MENU.COMPANIES',
            },
            {
              title: 'Location Csv Imports',
              root: true,
              icon: 'flaticon-placeholder-2',
              page: 'csv/list/Location',
              translate: 'MENU.LOCATIONS',
            },
            {
              title: 'Bouser Csv Imports',
              root: true,
              icon: 'flaticon-users',
              page: 'csv/list/BoUser',
              translate: 'APP.BOUSER',
            },
            {
              title: 'Appuser Csv Imports',
              root: true,
              icon: 'flaticon-users',
              page: 'csv/list/Customer',
              translate: 'APP.CUSTOMERS',
            },
            // {
            //   title: 'Promotion Target Csv Imports',
            //   root: true,
            //   icon: 'flaticon-graphic-1',
            //   page: 'csv/list/PromoTarget',
            //   translate: 'PROMO.TARGETS',
            // },
            {
              title: 'Target Csv Imports',
              root: true,
              icon: 'flaticon-graphic-1',
              page: 'csv/list/Target',
              translate: 'MENU.TARGET.AND.TURNOVER',
            },
            {
              title: 'User Sales Csv Imports',
              root: true,
              icon: 'flaticon-graphic-1',
              page: 'csv/list/Order',
              translate: 'PROMO.CUSTOMER.SALES',
            },
            {
              title: 'Turnover Csv Imports',
              root: true,
              icon: 'flaticon-graphic-1',
              page: 'csv/list/Turnover',
              translate: 'MENU.TURNOVERS',
            },
          ],
        },
        {
          title: 'Configuration',
          root: true,
          icon: 'flaticon-graphic',
          translate: 'MENU.CONFIGURATION',
          submenu: [
            {
              title: 'App',
              root: true,
              icon: 'flaticon-responsive',
              page: 'app/list',
              translate: 'MENU.APP',
            },
            {
              title: 'Companies',
              root: true,
              icon: 'flaticon-business',
              page: 'company/list',
              translate: 'MENU.COMPANIES',
            },
            {
              title: 'Locations',
              root: true,
              icon: 'flaticon-placeholder-2',
              page: 'location/list',
              translate: 'MENU.LOCATIONS',
            },
            {
              title: 'Category',
              root: true,
              icon: 'flaticon-squares',
              page: 'category/list',
              translate: 'MENU.USER.CATEGORIES',
            },
          ],
        },
        {
          title: 'Activity',
          root: true,
          icon: 'flaticon-app',
          page: 'appactivity',
          translate: 'MENU.APPACTIVITY',
        },
      ],
    },
  },
};
