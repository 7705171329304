import { isDataEmpty, isArrayEmpty } from './empty_helper';
import { isArray } from './type_helper';

export const uriEncoder = data => {
  const value = JSON.stringify(data);
  return encodeURIComponent(value);
};

export const queryEncoder = (prop, value) => {
  const query = {};

  if (isArray(value) && !isArrayEmpty(value)) {
    const condition = value.every(a => !isDataEmpty(a));

    if (!condition) {
      return query;
    } else {
      query[prop] = { $in: value };
    }
  } else if (!isDataEmpty(value)) {
    query[prop] = value;
  }

  return query;
};
