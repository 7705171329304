import {
  GET_ALL_APP_USER,
  GET_ALL_ACTIVE_APP_USER,
  GET_APP_USER_BY_ID,
  GET_APP_USER_COUNT,
  SET_APP_USER_TO_OPTION,
  REMOVE_ALL_APP_USER,
  REMOVE_APP_USER,
  REMOVE_ALL_ACTIVE_APP_USER,
  REMOVE_APP_USER_TO_OPTION,
  REMOVE_APP_USER_BY_ID,
  REMOVE_APP_USER_COUNT,
} from '../actions/actionTypes';

const initialState = {
  appUsers: { loaded: false, data: [], totalRows: 0 },
  activeAppUsers: { loaded: false, totalRows: 0 },
  appUser: { loaded: false, data: {} },
  appUserToOption: { loaded: false, data: [] },
  appUserCount: { loaded: false, data: [] },
};

const appUserReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_APP_USER:
      return {
        ...state,
        appUsers: {
          loaded: true,
          data: payload.customers,
          totalRows: payload.total_rows,
        },
      };
    case GET_ALL_ACTIVE_APP_USER:
      return { ...state, activeAppUsers: { loaded: true, totalRows: payload } };
    case GET_APP_USER_BY_ID:
      return { ...state, appUser: { loaded: true, data: payload } };
    case GET_APP_USER_COUNT:
      return { ...state, appUserCount: { loaded: true, data: payload } };
    case SET_APP_USER_TO_OPTION:
      return {
        ...state,
        appUserToOption: { loaded: true, data: payload },
      };

    case REMOVE_ALL_APP_USER:
      return {
        ...state,
        appUsers: { loaded: false, data: [], totalRows: 0 },
      };
    case REMOVE_ALL_ACTIVE_APP_USER:
      return { ...state, appUsers: { loaded: false, totalRows: 0 } };
    case REMOVE_APP_USER:
      return { ...state, appUser: { loaded: false, data: {} } };
    case REMOVE_APP_USER_COUNT:
      return { ...state, appUserCount: { loaded: false, data: [] } };
    case REMOVE_APP_USER_TO_OPTION:
      return { ...state, appUserToOption: { loaded: false, data: [] } };
    case REMOVE_APP_USER_BY_ID:
      let appUsers = state.appUsers.data.filter(user => payload !== user._id);
      return {
        ...state,
        appUsers: {
          loaded: true,
          data: appUsers,
          totalRows: state.appUsers.totalRows - 1,
        },
      };
    default:
      return state;
  }
};

export default appUserReducer;
