import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import Can from '../../../components/acl/Can';
import Cannot from '../../../components/acl/Cannot';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import AlertComponent from '../../../components/alert/AlertComponent';
import TableSearchBar from '../../../components/search/TableSearchBar';

import { queryEncoder } from '../../../helpers/uri_helper';
import { sortDirection } from '../../../helpers/sort_helper';
import {
  csvDownload,
  getAppActivity,
  removeAppActivity,
} from '../../../actions/appActivityAction';
import {
  setGroupToOption,
  removeGroupToOption,
} from '../../../actions/groupAction';
import { importTurnOverCSVFile } from '../../../actions/uploadAction';
import AppActivityListComponent from '../../../components/appActivity.js/AppActivityListComponent';

class AppActivityPage extends Component {
  constructor() {
    super();

    this.state = {
      data: [],
      total_rows: 0,
      page_number: 1,
      per_page: 10,
      search: '',
      sort_field: '_id',
      sort_direction: 1,
      company_code: '',
      total_sales: 0,
      loaded: false,
      filter: false,
    };
  }

  componentDidMount() {
    const { per_page } = this.state;

    this.props.getAppActivity(1, per_page);
  }

  static getDerivedStateFromProps(props, state) {
    let {
      appActivity: { appActivity },
    } = props;

    return {
      loaded: true,
      data: appActivity.data,
      total_rows: appActivity.totalRows,
    };
  }

  componentWillUnmount() {
    this.props.removeAppActivity();
  }

  handleFilter = event => {
    let { value, name } = event.target;

    const { per_page, target_search, sort_field, sort_direction } = this.state;
    let query = {};

    if (name === 'company_code') {
      query = {
        ...queryEncoder('q.company_code', value),
      };

      this.setState({ company_code: value });
    }

    this.props.getAppActivity(
      1,
      per_page,
      target_search,
      sort_field,
      sort_direction,
      query
    );

    this.setState({ page_number: 1 });
  };

  handleSearch = event => {
    let { value } = event.target;
    value = value.toLowerCase();

    const { per_page, sort_field, sort_direction, company_code } = this.state;

    const query = {
      ...queryEncoder('q.company_code', company_code),
    };

    this.setState({ page_number: 1, search: value }, () => {
      this.props.getAppActivity(
        1,
        per_page,
        value,
        sort_field,
        sort_direction,
        query
      );
    });
  };

  handleCsvExport = async () => {
    await csvDownload();
  };

  handlePageChange = page => {
    const {
      per_page,
      search,
      sort_field,
      sort_direction,
      company_code,
    } = this.state;

    const query = {
      ...queryEncoder('q.company_code', company_code),
    };

    this.setState({ page_number: page }, () => {
      this.props.getAppActivity(
        page,
        per_page,
        search,
        sort_field,
        sort_direction,
        query
      );
    });
  };

  handlePerRowsChange = (per_page, page) => {
    const { search, sort_field, sort_direction, company_code } = this.state;

    const query = {
      ...queryEncoder('q.company_code', company_code),
    };

    this.setState({ page_number: page, per_page: per_page }, () => {
      this.props.getAppActivity(
        page,
        per_page,
        search,
        sort_field,
        sort_direction,
        query
      );
    });
  };

  handleSort = (column, direction) => {
    const {
      per_page,
      search,
      company_code,
      location_code,
      sales_year,
    } = this.state;
    let sortField = column.selector;

    let sortDir = sortDirection(direction);

    if (sortField.includes('_name')) {
      sortField = sortField.replace('_name', '_code');
    }

    const query = {
      ...queryEncoder('q.company_code', company_code),
      ...queryEncoder('q.location_code', [location_code]),
      ...queryEncoder('q.sales_year', sales_year),
    };

    this.setState({ sort_field: sortField, sort_direction: sortDir }, () => {
      this.props.getAppActivity(1, per_page, search, sortField, sortDir, query);
    });
  };

  renderPage = () => {
    const {
      appActivity: { appActivity },
    } = this.props;

    return (
      <div className="main-content">
        <BreadCrumb
          paths={[
            {
              to: '/appActivity',
              name: <FormattedMessage id="APPACTIVITY.TITLE" />,
            },
          ]}
        />
        <hr />

        <div>
          <div className="row">
            <div className="col-md-6">
              <TableSearchBar search={event => this.handleSearch(event)} />
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <>
                <button
                  type="button"
                  className="btn btn-primary mr-2"
                  onClick={() => this.handleCsvExport()}
                >
                  <FormattedMessage id="CSV.EXPORT" />
                </button>
              </>
            </div>
          </div>

          <AlertComponent />

          <AppActivityListComponent
            formData={this.state}
            turnoversLoaded={appActivity.loaded}
            intl={this.props.intl}
            auth={this.props.auth}
            handleFilter={event => this.handleFilter(event)}
            handlePerRowsChange={this.handlePerRowsChange}
            handlePageChange={this.handlePageChange}
            handleSort={this.handleSort}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <Can
        role={this.props.auth.user.roles}
        perform="turnover:list"
        yes={() => this.renderPage()}
        no={() => <Cannot role={this.props.auth.user.roles} />}
      />
    );
  }
}

AppActivityPage.propTypes = {
  auth: PropTypes.object.isRequired,
  appActivity: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth.user,
  appActivity: state.app.appActivity,
  group: state.app.group,
});

export default injectIntl(
  connect(mapStateToProps, {
    getAppActivity,
    setGroupToOption,
    importTurnOverCSVFile,
    removeAppActivity,
    removeGroupToOption,
  })(AppActivityPage)
);
