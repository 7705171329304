import {
  GET_ALL_PUSH_TEMPLATE,
  GET_PUSH_TEMPLATE_BY_ID,
  GET_ALL_PUSH_TEMPLATE_TYPE,
  GET_PUSH_SHORT_CODES_BY_EVENT_TYPE,
  GET_ALL_CUSTOM_PUSH_TEMPLATE,
  GET_CUSTOM_PUSH_TEMPLATE_BY_ID,
  REMOVE_ALL_PUSH_TEMPLATE,
  REMOVE_ALL_PUSH_TEMPLATE_TYPE,
  REMOVE_ALL_PUSH_SHORT_CODE,
  REMOVE_ALL_CUSTOM_PUSH_TEMPLATE,
  REMOVE_PUSH_TEMPLATE,
  REMOVE_CUSTOM_PUSH_TEMPLATE,
} from '../actions/actionTypes';

const initialState = {
  pushTemplates: { loaded: false, data: [] },
  pushTemplate: { loaded: false, data: {} },
  customPushTemplates: { loaded: false, data: [] },
  customPushTemplate: { loaded: false, data: {} },
  pushTemplateTypes: { loaded: false, data: [] },
  pushShortCodes: { loaded: false, data: [], selectedEventType: '' },
};

const pushTemplateReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_PUSH_TEMPLATE:
      return { ...state, pushTemplates: { loaded: true, data: payload } };
    case GET_ALL_CUSTOM_PUSH_TEMPLATE:
      return {
        ...state,
        customPushTemplates: { loaded: true, data: payload },
      };
    case GET_PUSH_TEMPLATE_BY_ID:
      return { ...state, pushTemplate: { loaded: true, data: payload } };
    case GET_CUSTOM_PUSH_TEMPLATE_BY_ID:
      return { ...state, customPushTemplate: { loaded: true, data: payload } };
    case GET_ALL_PUSH_TEMPLATE_TYPE:
      return { ...state, pushTemplateTypes: { loaded: true, data: payload } };
    case GET_PUSH_SHORT_CODES_BY_EVENT_TYPE:
      return {
        ...state,
        pushShortCodes: {
          loaded: true,
          data: payload.shortcodes,
          selectedEventType: payload.eventType,
        },
      };

    case REMOVE_ALL_PUSH_TEMPLATE:
      return {
        ...state,
        pushTemplates: { loaded: false, data: [] },
      };
    case REMOVE_ALL_CUSTOM_PUSH_TEMPLATE:
      return {
        ...state,
        customPushTemplates: { loaded: false, data: [] },
      };
    case REMOVE_PUSH_TEMPLATE:
      return { ...state, pushTemplate: { loaded: false, data: {} } };
    case REMOVE_CUSTOM_PUSH_TEMPLATE:
      return { ...state, customPushTemplate: { loaded: false, data: {} } };
    case REMOVE_ALL_PUSH_TEMPLATE_TYPE:
      return { ...state, pushTemplateTypes: { loaded: false, data: [] } };
    case REMOVE_ALL_PUSH_SHORT_CODE:
      return {
        ...state,
        pushShortCodes: { loaded: false, data: [], selectedEventType: '' },
      };

    default:
      return state;
  }
};

export default pushTemplateReducer;
