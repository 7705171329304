import { isDataEmpty } from './empty_helper';

export const errorHelper = err => {
  let errors = [];
  errors = !isDataEmpty(err.response)
    ? err.response.data.errors.map(error => {
        return error.message;
      })
    : ['Ci sono stati alcuni errori'];

  return errors;
};
