import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import DataTable from 'react-data-table-component';

const columns = [
  {
    name: <FormattedMessage id="USER.CLIENT.CODE" />,
    selector: 'client_code',
    sortable: true,
  },
  {
    name: <FormattedMessage id="APPACTIVITY.CLIENTNAME" />,
    selector: 'client_name',
    sortable: true,
  },
  {
    name: <FormattedMessage id="APPACTIVITY.COMPANY" />,
    selector: 'company_name',
    sortable: true,
    grow: 2,
  },
  {
    name: <FormattedMessage id="APPACTIVITY.AGENTCODE" />,
    selector: 'agent_code',
    sortable: true,
  },
  {
    name: <FormattedMessage id="APPACTIVITY.AGENTNAME" />,
    selector: 'agent_name',
    sortable: true,
    grow: 2,
  },
  {
    name: <FormattedMessage id="APPACTIVITY.INITIALACCESS" />,
    selector: 'initial_access',
    sortable: true,
    cell: row => row.initial_access?.substring(0, 10),
  },
  {
    name: <FormattedMessage id="APPACTIVITY.LASTACCESS" />,
    selector: 'last_access',
    sortable: true,
    cell: row => row.last_access?.substring(0, 10),
  },
  {
    name: <FormattedMessage id="APPACTIVITY.TOTALACCESS" />,
    selector: 'total_access',
    sortable: true,
  },
  {
    name: <FormattedMessage id="APPACTIVITY.MONTHACCESS" />,
    selector: 'month_access',
    sortable: true,
  },
];

const AppActivityListTableComponent = memo(props => {
  const {
    formData: { data, total_rows },
    turnoversLoaded,
    handlePerRowsChange,
    handlePageChange,
    handleSort,
  } = props;

  return (
    <DataTable
      data={data}
      columns={columns}
      keyField="_id"
      striped={true}
      sortServer={true}
      onSort={handleSort}
      pagination={true}
      paginationServer={true}
      paginationTotalRows={total_rows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      progressPending={!turnoversLoaded}
      progressComponent={'Loading...'}
      persistTableHead={true}
      noHeader={true}
      noDataComponent={<FormattedMessage id="NO.DATA" />}
    />
  );
});

export default AppActivityListTableComponent;
