import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { isDataEmpty, isArrayEmpty } from '../../helpers/empty_helper';
import { removeError, removeMessage } from '../../actions/alertAction';

class AlertComponent extends PureComponent {
  constructor() {
    super();

    this.state = { message: '', error: [] };
  }

  static getDerivedStateFromProps(props, state) {
    const {
      alert: { message, error },
    } = props;

    if (!isDataEmpty(message)) {
      setTimeout(() => {
        props.removeMessage();
      }, 5000);
    }

    if (error.length > 0) {
      setTimeout(() => {
        props.removeError();
      }, 5000);
    }

    return null;
  }

  render() {
    const {
      alert: { message, error },
    } = this.props;

    return (
      <>
        <div className="clear" />

        {!isDataEmpty(message) ? (
          <div className="alert alert-success">{message}</div>
        ) : null}

        <div className="clear" />

        {!isArrayEmpty(error)
          ? error.map((e, key) => {
              return (
                <div key={key} className="alert text-danger">
                  {e}
                </div>
              );
            })
          : null}
      </>
    );
  }
}

AlertComponent.propTypes = {
  alert: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  alert: state.app.alert,
});

export default connect(mapStateToProps, { removeError, removeMessage })(
  AlertComponent
);
