import {
  GET_ALL_GROUP,
  GET_GROUP_BY_ID,
  SET_GROUP_TO_OPTION,
  UPDATE_GROUP_CUSTOMER,
  DELETE_GROUP,
  REMOVE_ALL_GROUP,
  REMOVE_GROUP,
  REMOVE_GROUP_TO_OPTION,
} from '../actions/actionTypes';

const initialState = {
  groups: { loaded: false, data: [], totalRows: 0 },
  group: { loaded: false, data: {} },
  groupToOption: { loaded: false, data: [] },
};

const groupReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_GROUP:
      return {
        ...state,
        groups: {
          loaded: true,
          data: payload.groups,
          totalRows: payload.total_rows,
        },
      };
    case GET_GROUP_BY_ID:
      return { ...state, group: { loaded: true, data: payload } };
    case SET_GROUP_TO_OPTION:
      return { ...state, groupToOption: { loaded: true, data: payload } };

    case UPDATE_GROUP_CUSTOMER:
      let groups = state.groups.data.map(g => {
        if (payload._id === g._id) {
          return {
            ...g,
            customers: payload.customers,
            company_code: payload.customers[0].company_code,
          };
        }
        return g;
      });
      return { ...state, groups: { loaded: true, data: groups } };
    case DELETE_GROUP:
      groups = state.groups.data.filter(g => payload !== g._id);
      return {
        ...state,
        groups: {
          loaded: true,
          data: groups,
          totalRows: state.groups.totalRows - 1,
        },
      };

    case REMOVE_ALL_GROUP:
      return { ...state, groups: { loaded: false, data: [], totalRows: 0 } };
    case REMOVE_GROUP:
      return { ...state, group: { loaded: false, data: {} } };
    case REMOVE_GROUP_TO_OPTION:
      return { ...state, groupToOption: { loaded: false, data: [] } };

    default:
      return state;
  }
};

export default groupReducer;
