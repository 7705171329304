import {
  GET_ALL_LUIGI,
  GET_LUIGI_BY_ID,
  PUBLISH_LUIGI,
  DELETE_LUIGI,
  REMOVE_ALL_LUIGI,
  REMOVE_LUIGI,
} from '../actions/actionTypes';

const initialState = {
  luigi: { loaded: false, data: [], totalRows: 0 },
  oneLuigi: { loaded: false, data: {} },
};

const luigiReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_LUIGI:
      return {
        ...state,
        luigi: {
          loaded: true,
          data: payload.luigi,
          totalRows: payload.total_rows,
        },
      };
    case GET_LUIGI_BY_ID:
      return { ...state, oneLuigi: { loaded: true, data: payload } };

    case PUBLISH_LUIGI:
      let luigi = state.luigi.data.map(n => {
        if (payload._id === n._id) {
          n.publication_date = payload.publication_date;
        }
        return n;
      });
      return { ...state, luigi: { loaded: true, data: luigi } };
    case DELETE_LUIGI:
      luigi = state.luigi.data.filter(n => payload !== n._id);
      return {
        ...state,
        luigi: {
          loaded: true,
          data: luigi,
          totalRows: state.luigi.totalRows - 1,
        },
      };

    case REMOVE_ALL_LUIGI:
      return { ...state, luigi: { loaded: false, data: [] } };
    case REMOVE_LUIGI:
      return { ...state, oneLuigi: { loaded: false, data: {} } };

    default:
      return state;
  }
};

export default luigiReducer;
