import {
  GET_ALL_REPORT,
  GET_REPORT_BY_ID,
  GET_ALL_CANDIDATE,
  GET_ALL_BOUSER_CANDIDATE,
  GET_ALL_CUSTOMER_CANDIDATE,
  REMEMBER_INFO,
  REMOVE_ALL_PROMOTION_REPORT,
  REMOVE_PROMOTION_REPORT,
  REMOVE_ALL_CANDIDATE,
} from '../actions/actionTypes';

const initialState = {
  reports: { loaded: false, data: [] },
  report: { loaded: false, data: [] },
  rememberedData: { loaded: false, data: {} },
  candidates: { loaded: false, data: [] },
  boUsers: { loaded: false, data: [] },
  customers: { loaded: false, data: [] },
};

const promotionReportReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_REPORT:
      return { ...state, reports: { loaded: true, data: payload } };
    case GET_REPORT_BY_ID:
      return { ...state, report: { loaded: true, data: payload } };
    case GET_ALL_CANDIDATE:
      return { ...state, candidates: { loaded: true, data: payload } };
    case GET_ALL_BOUSER_CANDIDATE:
      return { ...state, boUsers: { loaded: true, data: payload } };
    case GET_ALL_CUSTOMER_CANDIDATE:
      return { ...state, customers: { loaded: true, data: payload } };
    case REMEMBER_INFO:
      return { ...state, rememberedData: { loaded: true, data: payload } };

    case REMOVE_ALL_PROMOTION_REPORT:
      return { ...state, reports: { loaded: false, data: [] } };
    case REMOVE_PROMOTION_REPORT:
      return { ...state, report: { loaded: false, data: [] } };
    case REMOVE_ALL_CANDIDATE:
      return {
        ...state,
        boUsers: { loaded: false, data: [] },
        customers: { loaded: false, data: [] },
      };

    default:
      return state;
  }
};

export default promotionReportReducer;
