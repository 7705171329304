import {
  GET_ALL_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE_BY_ID,
  GET_ALL_EMAIL_TEMPLATE_TYPE,
  GET_EMAIL_SHORT_CODES_BY_EVENT_TYPE,
  GET_ALL_CUSTOM_EMAIL_TEMPLATE,
  GET_CUSTOM_EMAIL_TEMPLATE_BY_ID,
  REMOVE_ALL_EMAIL_TEMPLATE,
  REMOVE_ALL_EMAIL_TEMPLATE_TYPE,
  REMOVE_ALL_EMAIL_SHORT_CODE,
  REMOVE_ALL_CUSTOM_EMAIL_TEMPLATE,
  REMOVE_EMAIL_TEMPLATE,
  REMOVE_CUSTOM_EMAIL_TEMPLATE,
} from '../actions/actionTypes';

const initialState = {
  emailTemplates: { loaded: false, data: [] },
  emailTemplate: { loaded: false, data: {} },
  customEmailTemplates: { loaded: false, data: [] },
  customEmailTemplate: { loaded: false, data: {} },
  emailTemplateTypes: { loaded: false, data: [] },
  emailShortCodes: { loaded: false, data: [], selectedEventType: '' },
};

const emailTemplateReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_EMAIL_TEMPLATE:
      return { ...state, emailTemplates: { loaded: true, data: payload } };
    case GET_ALL_CUSTOM_EMAIL_TEMPLATE:
      return {
        ...state,
        customEmailTemplates: { loaded: true, data: payload },
      };
    case GET_EMAIL_TEMPLATE_BY_ID:
      return { ...state, emailTemplate: { loaded: true, data: payload } };
    case GET_CUSTOM_EMAIL_TEMPLATE_BY_ID:
      return { ...state, customEmailTemplate: { loaded: true, data: payload } };
    case GET_ALL_EMAIL_TEMPLATE_TYPE:
      return { ...state, emailTemplateTypes: { loaded: true, data: payload } };
    case GET_EMAIL_SHORT_CODES_BY_EVENT_TYPE:
      return {
        ...state,
        emailShortCodes: {
          loaded: true,
          data: payload.shortcodes,
          selectedEventType: payload.eventType,
        },
      };

    case REMOVE_ALL_EMAIL_TEMPLATE:
      return {
        ...state,
        emailTemplates: { loaded: false, data: [] },
      };
    case REMOVE_ALL_CUSTOM_EMAIL_TEMPLATE:
      return {
        ...state,
        customEmailTemplates: { loaded: false, data: [] },
      };
    case REMOVE_EMAIL_TEMPLATE:
      return { ...state, emailTemplate: { loaded: false, data: {} } };
    case REMOVE_CUSTOM_EMAIL_TEMPLATE:
      return { ...state, customEmailTemplate: { loaded: false, data: {} } };
    case REMOVE_ALL_EMAIL_TEMPLATE_TYPE:
      return { ...state, emailTemplateTypes: { loaded: false, data: [] } };
    case REMOVE_ALL_EMAIL_SHORT_CODE:
      return {
        ...state,
        emailShortCodes: { loaded: false, data: [], selectedEventType: '' },
      };

    default:
      return state;
  }
};

export default emailTemplateReducer;
