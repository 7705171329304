import React, { useState } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField, InputAdornment } from '@material-ui/core';
import clsx from 'clsx';
import * as auth from '../../store/ducks/auth.duck';
import { changeBoUserPassword } from '../../actions/authAction';
import AlertComponent from '../../components/alert/AlertComponent';

function FirstLogin(props) {
  // const [isRequested, setIsRequested] = useState(false);

  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: '2.5rem',
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: '3.5rem' });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: '2.5rem' });
  };

  const [showPassword, setShowPassword] = useState({
    newPass: false,
    oldPass: false,
    confPass: false,
  });

  return (
    <div className="kt-login__body">
      <div className="kt-login__form">
        <div className="kt-login__title">
          <AlertComponent />
          <h3>
            {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
            <FormattedMessage id="AUTH.CHANGE.PASSWORD.TITLE" />
          </h3>
        </div>

        <Formik
          initialValues={{
            confirmpassword: '',
            password: '',
            oldpassword: '',
          }}
          validate={values => {
            const validPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+._]).{8,}$/.test(
              values.password
            );

            const errors = {};

            if (!values.password) {
              errors.password = intl.formatMessage({
                id: 'AUTH.VALIDATION.REQUIRED_FIELD',
              });
            }

            if (!values.oldpassword) {
              errors.oldpassword = intl.formatMessage({
                id: 'AUTH.VALIDATION.REQUIRED_FIELD',
              });
            }
            if (!values.confirmpassword) {
              errors.confirmpassword = intl.formatMessage({
                id: 'AUTH.VALIDATION.REQUIRED_FIELD',
              });
            }

            if (values.password !== values.confirmpassword) {
              errors.confirmpassword = intl.formatMessage({
                id: 'AUTH.VALIDATION.MATCH',
              });
            }

            if (!validPassword) {
              errors.password = intl.formatMessage({
                id: 'AUTH.VALIDATION.PASSWORD_VALIDATION',
              });
            }

            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            enableLoading();
            setTimeout(() => {
              changeBoUserPassword(values.oldpassword, values.password)
                .then(data => {
                  disableLoading();
                  // props.login(token);
                  props.history.push('/success', {
                    from: props.location,
                  });
                })
                .catch(err => {
                  disableLoading();
                  setSubmitting(false);

                  setStatus(
                    intl.formatMessage({
                      id: 'AUTH.VALIDATION.INVALID_LOGIN',
                    })
                  );
                });
            }, 1000);
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              noValidate={true}
              autoComplete="off"
              className="kt-form"
              onSubmit={handleSubmit}
            >
              {status ? (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              ) : (
                <></>
              )}

              <div className="form-group">
                <TextField
                  type={showPassword.oldPass ? 'text' : 'password'}
                  margin="normal"
                  label={<FormattedMessage id="AUTH.CURRENT.PASSWORD" />}
                  className="kt-width-full"
                  name="oldpassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.oldpassword}
                  helperText={touched.oldpassword && errors.oldpassword}
                  error={Boolean(touched.oldpassword && errors.oldpassword)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            showPassword.oldPass === false
                              ? setShowPassword({
                                  ...showPassword,
                                  oldPass: true,
                                })
                              : setShowPassword({
                                  ...showPassword,
                                  oldPass: false,
                                })
                          }
                        >
                          {showPassword.oldPass ? (
                            <i className="fas fa-eye fa-lg" />
                          ) : (
                            <i className="fas fa-eye-slash fa-lg" />
                          )}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className="form-group">
                <TextField
                  type={showPassword.newPass ? 'text' : 'password'}
                  margin="normal"
                  label={<FormattedMessage id="AUTH.NEW.PASSWORD" />}
                  className="kt-width-full"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            showPassword.newPass === false
                              ? setShowPassword({
                                  ...showPassword,
                                  newPass: true,
                                })
                              : setShowPassword({
                                  ...showPassword,
                                  newPass: false,
                                })
                          }
                        >
                          {showPassword.newPass ? (
                            <i className="fas fa-eye fa-lg" />
                          ) : (
                            <i className="fas fa-eye-slash fa-lg" />
                          )}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="form-group">
                <TextField
                  type={showPassword.confPass ? 'text' : 'password'}
                  margin="normal"
                  label={<FormattedMessage id="AUTH.CONFIRM.NEW.PASSWORD" />}
                  className="kt-width-full"
                  name="confirmpassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmpassword}
                  helperText={touched.confirmpassword && errors.confirmpassword}
                  error={Boolean(
                    touched.confirmpassword && errors.confirmpassword
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            showPassword.confPass === false
                              ? setShowPassword({
                                  ...showPassword,
                                  confPass: true,
                                })
                              : setShowPassword({
                                  ...showPassword,
                                  confPass: false,
                                })
                          }
                        >
                          {showPassword.confPass ? (
                            <i className="fas fa-eye fa-lg" />
                          ) : (
                            <i className="fas fa-eye-slash fa-lg" />
                          )}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className="kt-login__actions">
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={isSubmitting}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading,
                    }
                  )}`}
                  style={loadingButtonStyle}
                >
                  <FormattedMessage id="APP.SAVE" />
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(FirstLogin));
