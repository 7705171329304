export const GET_ERRORS = 'GET_ERRORS';
export const REMOVE_ERRORS = 'REMOVE_ERRORS';

export const SEND_SUCCESS_MESSAGE = 'SEND_SUCCESS_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// Category
export const GET_ALL_USER_CATEGORY = 'GET_ALL_USER_CATEGORY';
export const SET_CATEGORY_TO_OPTION = 'SET_CATEGORY_TO_OPTION';

export const DELETE_CATEGORY = 'DELETE_CATEGORY';

export const REMOVE_ALL_CATEGORY = 'REMOVE_ALL_CATEGORY';
export const REMOVE_CATEGORY_TO_OPTION = 'REMOVE_CATEGORY_TO_OPTION';

// Company
export const GET_ALL_COMPANY = 'GET_ALL_COMPANY';
export const GET_COMPANY_BY_ID = 'GET_COMPANY_BY_ID';
export const SET_COMPANY_TO_OPTION = 'SET_COMPANY_TO_OPTION';
export const DELETE_COMPANY_BY_ID = 'DELETE_COMPANY_BY_ID';
export const REMOVE_ALL_COMPANY = 'REMOVE_ALL_COMPANY';
export const REMOVE_COMPANY = 'REMOVE_COMPANY';
export const REMOVE_COMPANY_TO_OPTION = 'REMOVE_COMPANY_TO_OPTION';
export const REMOVE_PROMOTION_BY_ID = 'REMOVE_COMPANY_BY_ID';

//Location
export const GET_ALL_LOCATION = 'GET_ALL_LOCATION';
export const GET_LOCATION_BY_ID = 'GET_LOCATION_BY_ID';
export const SET_LOCATION_TO_OPTION = 'SET_LOCATION_TO_OPTION';

export const DELETE_LOCATION = 'DELETE_LOCATION';

export const REMOVE_ALL_LOCATION = 'REMOVE_ALL_LOCATION';
export const REMOVE_LOCATION = 'REMOVE_LOCATION';
export const REMOVE_LOCATION_TO_OPTION = 'REMOVE_LOCATION_TO_OPTION';

// BoUser
export const GET_ALL_BO_USER = 'GET_ALL_BO_USER';
export const GET_BO_USER_BY_ID = 'GET_BO_USER_BY_ID';
export const GET_ALL_USER_CODE = 'GET_ALL_USER_CODE';
export const SET_AGENTS_TO_OPTION = 'SET_AGENTS_TO_OPTION';
export const SET_AREA_MANAGER_TO_OPTION = 'SET_AREA_MANAGER_TO_OPTION';

export const REMOVE_ALL_BO_USER = 'REMOVE_ALL_BO_USER';
export const REMOVE_BO_USER = 'REMOVE_BO_USER';
export const REMOVE_ALL_USER_CODE = 'REMOVE_ALL_USER_CODE';
export const REMOVE_BO_USER_TO_OPTION = 'REMOVE_BO_USER_TO_OPTION';
export const REMOVE_BO_USER_BY_ID = 'REMOVE_BO_USER_BY_ID';

// AppUser
export const GET_ALL_APP_USER = 'GET_ALL_APP_USER';
export const GET_ALL_ACTIVE_APP_USER = 'GET_ALL_ACTIVE_APP_USER';
export const GET_APP_USER_COUNT = 'GET_APP_USER_COUNT';
export const GET_APP_USER_BY_ID = 'GET_APP_USER_BY_ID';
export const SET_APP_USER_TO_OPTION = 'SET_APP_USER_TO_OPTION';

export const REMOVE_ALL_APP_USER = 'REMOVE_ALL_APP_USER';
export const REMOVE_ALL_ACTIVE_APP_USER = 'REMOVE_ALL_ACTIVE_APP_USER';
export const REMOVE_APP_USER = 'REMOVE_APP_USER';
export const REMOVE_APP_USER_TO_OPTION = 'REMOVE_APP_USER_TO_OPTION';
export const REMOVE_APP_USER_BY_ID = 'REMOVE_APP_USER_BY_ID';
export const REMOVE_APP_USER_COUNT = 'REMOVE_APP_USER_COUNT';

// App
export const GET_ALL_APP = 'GET_ALL_APP';

export const REMOVE_ALL_APP = 'REMOVE_ALL_APP';

// Tiles
export const GET_ALL_TILE = 'GET_ALL_TILE';
export const GET_TILE_BY_ID = 'GET_TILE_BY_ID';

export const DELETE_TILE = 'DELETE_TILE';

export const REMOVE_ALL_TILE = 'REMOVE_ALL_TILE';
export const REMOVE_TILE = 'REMOVE_TILE';

// Email Template
export const GET_ALL_EMAIL_TEMPLATE = 'GET_ALL_EMAIL_TEMPLATE';
export const GET_EMAIL_TEMPLATE_BY_ID = 'GET_EMAIL_TEMPLATE_BY_ID';
export const GET_ALL_EMAIL_TEMPLATE_TYPE = 'GET_ALL_EMAIL_TEMPLATE_TYPE';
export const GET_EMAIL_SHORT_CODES_BY_EVENT_TYPE =
  'GET_EMAIL_SHORT_CODES_BY_EVENT_TYPE';
export const GET_ALL_CUSTOM_EMAIL_TEMPLATE = 'GET_ALL_CUSTOM_EMAIL_TEMPLATE';
export const GET_CUSTOM_EMAIL_TEMPLATE_BY_ID =
  'GET_CUSTOM_EMAIL_TEMPLATE_BY_ID';

export const REMOVE_ALL_EMAIL_TEMPLATE = 'REMOVE_ALL_EMAIL_TEMPLATE';
export const REMOVE_EMAIL_TEMPLATE = 'REMOVE_EMAIL_TEMPLATE';
export const REMOVE_ALL_EMAIL_TEMPLATE_TYPE = 'REMOVE_ALL_EMAIL_TEMPLATE_TYPE';
export const REMOVE_ALL_EMAIL_SHORT_CODE = 'REMOVE_ALL_EMAIL_SHORT_CODE';
export const REMOVE_ALL_CUSTOM_EMAIL_TEMPLATE =
  'REMOVE_ALL_CUSTOM_EMAIL_TEMPLATE';
export const REMOVE_CUSTOM_EMAIL_TEMPLATE = 'REMOVE_CUSTOM_EMAIL_TEMPLATE';

// Push Template
export const GET_ALL_PUSH_TEMPLATE = 'GET_ALL_PUSH_TEMPLATE';
export const GET_PUSH_TEMPLATE_BY_ID = 'GET_PUSH_TEMPLATE_BY_ID';
export const GET_ALL_PUSH_TEMPLATE_TYPE = 'GET_ALL_PUSH_TEMPLATE_TYPE';
export const GET_PUSH_SHORT_CODES_BY_EVENT_TYPE =
  'GET_PUSH_SHORT_CODES_BY_EVENT_TYPE';
export const GET_ALL_CUSTOM_PUSH_TEMPLATE = 'GET_ALL_CUSTOM_PUSH_TEMPLATE';
export const GET_CUSTOM_PUSH_TEMPLATE_BY_ID = 'GET_CUSTOM_PUSH_TEMPLATE_BY_ID';

export const REMOVE_ALL_PUSH_TEMPLATE = 'REMOVE_ALL_PUSH_TEMPLATE';
export const REMOVE_PUSH_TEMPLATE = 'REMOVE_PUSH_TEMPLATE';
export const REMOVE_ALL_PUSH_TEMPLATE_TYPE = 'REMOVE_ALL_PUSH_TEMPLATE_TYPE';
export const REMOVE_ALL_PUSH_SHORT_CODE = 'REMOVE_ALL_PUSH_SHORT_CODE';
export const REMOVE_ALL_CUSTOM_PUSH_TEMPLATE =
  'REMOVE_ALL_CUSTOM_PUSH_TEMPLATE';
export const REMOVE_CUSTOM_PUSH_TEMPLATE = 'REMOVE_CUSTOM_PUSH_TEMPLATE';

// News
export const GET_ALL_NEWS = 'GET_ALL_NEWS';
export const GET_NEWS_BY_ID = 'GET_NEWS_BY_ID';
export const PUBLISH_NEWS = 'PUBLISH_NEWS';

export const DELETE_NEWS = 'DELETE_NEWS';

export const REMOVE_ALL_NEWS = 'REMOVE_ALL_NEWS';
export const REMOVE_NEWS = 'REMOVE_NEWS';

// Luigi COmini Onlus
export const GET_ALL_LUIGI = 'GET_ALL_LUIGI';
export const GET_LUIGI_BY_ID = 'GET_LUIGI_BY_ID';
export const PUBLISH_LUIGI = 'PUBLISH_LUIGI';

export const DELETE_LUIGI = 'DELETE_LUIGI';

export const REMOVE_ALL_LUIGI = 'REMOVE_ALL_LUIGI';
export const REMOVE_LUIGI = 'REMOVE_LUIGI';

// Journey
export const GET_ALL_JOURNEY = 'GET_ALL_JOURNEY';
export const GET_JOURNEY_BY_ID = 'GET_JOURNEY_BY_ID';

export const DELETE_JOURNEY = 'DELETE_JOURNEY';

export const REMOVE_ALL_JOURNEY = 'REMOVE_ALL_JOURNEY';
export const REMOVE_JOURNEY = 'REMOVE_JOURNEY';

// Survey
export const GET_ALL_SURVEY = 'GET_ALL_SURVEY';
export const GET_SURVEY_BY_ID = 'GET_SURVEY_BY_ID';
export const GET_ALL_QUESTION = 'GET_ALL_QUESTION';
export const GET_QUESTION_BY_ID = 'GET_QUESTION_BY_ID';
export const GET_SURVEY_QUESTIONS = 'GET_SURVEY_QUESTIONS';

export const DELETE_SURVEY = 'DELETE_SURVEY';
export const DELETE_QUESTION = 'DELETE_QUESTION';

export const REMOVE_ALL_SURVEY = 'REMOVE_ALL_SURVEY';
export const REMOVE_SURVEY = 'REMOVE_SURVEY';
export const REMOVE_ALL_QUESTION = 'REMOVE_ALL_QUESTION';
export const REMOVE_QUESTION = 'REMOVE_QUESTION';

// Target
export const GET_ALL_TARGET = 'GET_ALL_TARGET';
export const GET_TARGET_BY_ID = 'GET_TARGET_BY_ID';
export const GET_CUSTOMER_TURNOVER = 'GET_CUSTOMER_TURNOVER';
export const GET_TARGET_ACHIEVED_COUNT = 'GET_TARGET_ACHIEVED_COUNT';

export const REMOVE_ALL_TARGET = 'REMOVE_ALL_TARGET';
export const REMOVE_TARGET = 'REMOVE_TARGET';
export const REMOVE_CUSTOMER_TURNOVER = 'REMOVE_CUSTOMER_TURNOVER';

// Csv
export const GET_ALL_CSV = 'GET_ALL_CSV';
export const REMOVE_ALL_CSV = 'REMOVE_ALL_CSV';

// Promotion
export const GET_ALL_PROMOTION = 'GET_ALL_PROMOTION';
export const GET_ALL_ACTIVE_PROMOTION = 'GET_ALL_ACTIVE_PROMOTION';
export const GET_PROMOTION_BY_ID = 'GET_PROMOTION_BY_ID';
export const GET_ALL_GIFT = 'GET_ALL_GIFT';
export const GET_GIFT_BY_ID = 'GET_GIFT_BY_ID';
export const GET_ALL_AWARD_OPTION = 'GET_ALL_AWARD_OPTION';
export const GET_AWARD_OPTION_BY_ID = 'GET_AWARD_OPTION_BY_ID';
export const GET_ALL_ADDITIONAL_AWARD = 'GET_ALL_ADDITIONAL_AWARD';
export const GET_ADDITIONAL_AWARD_BY_ID = 'GET_ADDITIONAL_AWARD_BY_ID';

export const REMOVE_ALL_PROMOTION = 'REMOVE_ALL_PROMOTION';
export const REMOVE_ALL_ACTIVE_PROMOTION = 'REMOVE_ALL_ACTIVE_PROMOTION';
export const REMOVE_PROMOTION = 'REMOVE_PROMOTION';
export const REMOVE_ALL_AWARD = 'REMOVE_ALL_AWARD';
export const REMOVE_AWARD = 'REMOVE_AWARD';
export const REMOVE_ALL_GIFT = 'REMOVE_ALL_GIFT';
export const REMOVE_GIFT = 'REMOVE_GIFT';
export const REMOVE_ALL_ADDITIONAL_AWARD = 'REMOVE_ALL_ADDITIONAL_AWARD';
export const REMOVE_ADDITIONAL_AWARD = 'REMOVE_ADDITIONAL_AWARD';
export const REMOVE_GIFT_BY_ID = 'REMOVE_GIFT_BY_ID';

// Promotion Report
export const GET_ALL_REPORT = 'GET_ALL_REPORT';
export const GET_REPORT_BY_ID = 'GET_REPORT_BY_ID';
export const GET_ALL_CANDIDATE = 'GET_ALL_CANDIDATE';
export const GET_ALL_CUSTOMER_CANDIDATE = 'GET_ALL_CUSTOMER_CANDIDATE';
export const GET_ALL_BOUSER_CANDIDATE = 'GET_ALL_BOUSER_CANDIDATE';
export const REMEMBER_INFO = 'REMEMBER_INFO';

export const REMOVE_ALL_PROMOTION_REPORT = 'REMOVE_ALL_PROMOTION_REPORT';
export const REMOVE_PROMOTION_REPORT = 'REMOVE_PROMOTION_REPORT';
export const REMOVE_ALL_CANDIDATE = 'REMOVE_ALL_CANDIDATE';

// Turnover
export const GET_ALL_TURNOVER = 'GET_ALL_TURNOVER';

export const REMOVE_ALL_TURNOVER = 'REMOVE_ALL_TURNOVER';

// User sale
export const GET_ALL_USER_SALE = 'GET_ALL_USER_SALE';
export const GET_USER_SALE_BY_ID = 'GET_USER_SALE_BY_ID';

export const REMOVE_ALL_USER_SALE = 'REMOVE_ALL_USER_SALE';
export const REMOVE_USER_SALE = 'REMOVE_USER_SALE';

// Group
export const GET_ALL_GROUP = 'GET_ALL_GROUP';
export const GET_GROUP_BY_ID = 'GET_GROUP_BY_ID';
export const SET_GROUP_TO_OPTION = 'SET_GROUP_TO_OPTION';

export const DELETE_GROUP = 'DELETE_GROUP';
export const UPDATE_GROUP_CUSTOMER = 'UPDATE_GROUP_CUSTOMER';

export const REMOVE_ALL_GROUP = 'REMOVE_ALL_GROUP';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const REMOVE_GROUP_TO_OPTION = 'REMOVE_GROUP_TO_OPTION';

// App Activity
export const GET_APP_ACTIVITY = 'GET_APP_ACTIVITY';

export const REMOVE_ALL_APPACTIVITY = 'REMOVE_ALL_APPACTIVITY';
