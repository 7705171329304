import {
  GET_ALL_PROMOTION,
  GET_ALL_ACTIVE_PROMOTION,
  GET_PROMOTION_BY_ID,
  GET_ALL_GIFT,
  GET_GIFT_BY_ID,
  GET_ALL_AWARD_OPTION,
  GET_AWARD_OPTION_BY_ID,
  GET_ALL_ADDITIONAL_AWARD,
  GET_ADDITIONAL_AWARD_BY_ID,
  REMOVE_ALL_PROMOTION,
  REMOVE_PROMOTION,
  REMOVE_ALL_ACTIVE_PROMOTION,
  REMOVE_ALL_AWARD,
  REMOVE_AWARD,
  REMOVE_ALL_GIFT,
  REMOVE_GIFT,
  REMOVE_ALL_ADDITIONAL_AWARD,
  REMOVE_ADDITIONAL_AWARD,
  REMOVE_PROMOTION_BY_ID,
  REMOVE_GIFT_BY_ID,
} from '../actions/actionTypes';

const initialState = {
  promotions: { loaded: false, data: [], totalRows: 0 },
  activePromotions: { loaded: false, totalRows: 0 },
  promotion: { loaded: false, data: {} },
  awardOptions: { loaded: false, data: [] },
  awardOption: { loaded: false, data: {} },
  gifts: { loaded: false, data: [] },
  gift: { loaded: false, data: {} },
  additionalAwards: { loaded: false, data: [] },
  additionalAward: { loaded: false, data: {} },
};

const promotionReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_PROMOTION:
      return {
        ...state,
        promotions: {
          loaded: true,
          data: payload.promotions,
          totalRows: payload.total_rows,
        },
      };
    case GET_ALL_ACTIVE_PROMOTION:
      return {
        ...state,
        activePromotions: { loaded: true, totalRows: payload },
      };
    case GET_PROMOTION_BY_ID:
      return { ...state, promotion: { loaded: true, data: payload } };
    case GET_ALL_AWARD_OPTION:
      return { ...state, awardOptions: { loaded: true, data: payload } };
    case GET_AWARD_OPTION_BY_ID:
      return { ...state, awardOption: { loaded: true, data: payload } };
    case GET_ALL_GIFT:
      return { ...state, gifts: { loaded: true, data: payload } };
    case GET_GIFT_BY_ID:
      return { ...state, gift: { loaded: true, data: payload } };
    case GET_ALL_ADDITIONAL_AWARD:
      return { ...state, additionalAwards: { loaded: true, data: payload } };
    case GET_ADDITIONAL_AWARD_BY_ID:
      return { ...state, additionalAward: { loaded: true, data: payload } };

    case REMOVE_ALL_PROMOTION:
      return {
        ...state,
        promotions: { loaded: false, data: [], totalRows: 0 },
      };
    case REMOVE_ALL_ACTIVE_PROMOTION:
      return { ...state, activePromotions: { loaded: false, totalRows: 0 } };
    case REMOVE_PROMOTION:
      return { ...state, promotion: { loaded: false, data: {} } };
    case REMOVE_ALL_AWARD:
      return { ...state, awardOptions: { loaded: false, data: [] } };
    case REMOVE_AWARD:
      return { ...state, awardOption: { loaded: false, data: {} } };
    case REMOVE_ALL_GIFT:
      return { ...state, gifts: { loaded: false, data: [] } };
    case REMOVE_GIFT:
      return { ...state, gift: { loaded: false, data: {} } };
    case REMOVE_ALL_ADDITIONAL_AWARD:
      return { ...state, additionalAwards: { loaded: false, data: [] } };
    case REMOVE_ADDITIONAL_AWARD:
      return { ...state, additionalAward: { loaded: false, data: {} } };
    case REMOVE_PROMOTION_BY_ID:
      let promotions = state.promotions.data.filter(p => payload !== p._id);
      return {
        ...state,
        promotions: {
          loaded: true,
          data: promotions,
          totalRows: state.promotions.totalRows - 1,
        },
      };
      case REMOVE_GIFT_BY_ID:
      let gift = state.promotions.data.filter(p => payload !== p._id);
      return {
        ...state,
        gifts: {
          loaded: true,
          data: gift,
          totalRows: state.gift.totalRows - 1,
        },
      };
    default:
      return state;
  }
};

export default promotionReducer;
