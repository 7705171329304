import React, { memo } from 'react';
import { Redirect } from 'react-router-dom';

const Cannot = memo(props => {
  const { role } = props;

  switch (role) {
    case 'admin':
      return <Redirect exact to="/" />;
    case 'company_manager':
    case 'site_manager':
    case 'area_manager':
      return <Redirect exact to="/bo-user/list" />;
    case 'agent':
      return <Redirect exact to="/app-user/list" />;
    default:
      return <Redirect exact to="/" />;
  }
});

export default Cannot;
