import axios from '../axios';
import { BOUSER_BE_URL, AUTH_BE_URL } from '../helpers/api_helper';
import {
  GET_ERRORS,
  SEND_SUCCESS_MESSAGE,
  GET_ALL_BO_USER,
  GET_BO_USER_BY_ID,
  GET_ALL_USER_CODE,
  SET_AGENTS_TO_OPTION,
  SET_AREA_MANAGER_TO_OPTION,
  REMOVE_ALL_BO_USER,
  REMOVE_BO_USER,
  REMOVE_ALL_USER_CODE,
  REMOVE_BO_USER_TO_OPTION,
  REMOVE_BO_USER_BY_ID,
} from './actionTypes';

import {
  companyFilter,
  locationFilter,
  hasString,
  hasArray,
  createArray,
  removeComma,
} from '../helpers/data_helper';
import { isDataEmpty } from '../helpers/empty_helper';
import { errorHelper } from '../helpers/error_helper';

// get
export const getAllBoUser = (
  page,
  pageSize,
  searchTerm = '',
  sortField = '_id',
  sortDir = 1,
  query = {}
) => dispatch => {
  dispatch({ type: REMOVE_ALL_BO_USER });

  axios
    .get(BOUSER_BE_URL, {
      params: { page, pageSize, searchTerm, sortField, sortDir, ...query },
    })
    .then(res => {
      let {
        data: { bousers, total_rows },
      } = res.data;

      bousers = bousers.map(d => {
        let location_name = '';
        const companies = companyFilter(d.company);
        const locations = locationFilter(d.locations);

        locations.map(l => {
          location_name = location_name.concat(l.location_name, ', ');
          return location_name;
        });

        return {
          _id: hasString(d._id),
          code: hasString(d.code),
          firstname: hasString(d.firstname),
          surname: hasString(d.surname),
          email: hasString(d.email),
          roles: hasString(d.roles),
          company_name: !isDataEmpty(companies) ? companies.company_name : '_',
          location_name: removeComma(location_name),
          // last_access: d.last_access,
          companies: createArray(companies),
          locations,
        };
      });

      dispatch({ type: GET_ALL_BO_USER, payload: { bousers, total_rows } });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

export const setAgentsToOption = (company_code, location_code) => dispatch => {
  let url = '';
  if (!company_code) {
    url = `${BOUSER_BE_URL}/role/agent`;
  } else {
    if (!location_code) {
      url = `${BOUSER_BE_URL}/company/${company_code}/role/agent`;
    } else {
      url = `${BOUSER_BE_URL}/company/${company_code}/location/${location_code}/role/agent`;
    }
  }

  axios
    .get(url)
    .then(res => {
      let {
        data: { bousers },
      } = res.data;

      bousers = bousers.map(d => {
        return {
          _id: hasString(d._id),
          code: hasString(d.code),
          firstname: hasString(d.firstname),
          surname: hasString(d.surname),
          roles: hasString(d.roles),
          company_code: hasString(d.company_code),
          location_code: hasArray(d.location_code),
        };
      });

      dispatch({
        type: SET_AGENTS_TO_OPTION,
        payload: { agent: bousers, company_code, location_code },
      });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

export const setAreaManagerToOption = company_code => dispatch => {
  axios
    .get(`${BOUSER_BE_URL}/company/${company_code}/role/area_manager`)
    .then(res => {
      let {
        data: { bousers },
      } = res.data;

      bousers = bousers.map(d => {
        return {
          _id: hasString(d._id),
          code: hasString(d.code),
          firstname: hasString(d.firstname),
          surname: hasString(d.surname),
          roles: hasString(d.roles),
          company_code: hasString(d.company_code),
          location_code: hasArray(d.location_code),
        };
      });

      dispatch({
        type: SET_AREA_MANAGER_TO_OPTION,
        payload: { areaManager: bousers, company_code },
      });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

export const getBoUserById = _id => dispatch => {
  axios
    .get(`${BOUSER_BE_URL}/${_id}`)
    .then(res => {
      let {
        data: { bouser },
      } = res.data;

      const companies = companyFilter(bouser.company);
      const locations = locationFilter(bouser.locations);

      bouser = {
        code: hasString(bouser.code),
        firstname: hasString(bouser.firstname),
        surname: hasString(bouser.surname),
        email: hasString(bouser.email),
        roles: hasString(bouser.roles),
        telephone: hasString(bouser.telephone),
        company_code: hasString(bouser.company_code),
        location_code: hasArray(bouser.location_code),
        companies: createArray(companies),
        locations,
      };

      dispatch({ type: GET_BO_USER_BY_ID, payload: bouser });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

export const getAllUserCode = token => dispatch => {
  axios
    .get(`${AUTH_BE_URL}/reset/listaccounts?forget_password_token=${token}`)
    .then(res => {
      const {
        data: { accounts },
      } = res.data;

      dispatch({ type: GET_ALL_USER_CODE, payload: accounts });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

export const csvDownload = async () => {
  const response = await axios.get(`${BOUSER_BE_URL}/csv`, {
    responseType: 'blob',
  });
  const url = window.URL.createObjectURL(response.data);
  const tempLink = document.createElement('a');
  tempLink.href = url;
  tempLink.setAttribute('download', 'bousers.csv');
  tempLink.click();
};

// post
export const createBoUser = (data, history) => dispatch => {
  const user = {
    firstname: data.firstname,
    surname: data.surname,
    email: data.email,
    roles: data.roles,
    code: data.code,
    company_code: data.company_code,
    location_code: data.location_code,
    telephone: data.telephone,
  };

  axios
    .post(`${AUTH_BE_URL}/bouser/create`, user)
    .then(res => {
      const { message } = res.data;

      dispatch({ type: SEND_SUCCESS_MESSAGE, payload: message });

      history.push('/bo-user/list');
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

// put
export const updateBoUser = (_id, data, history) => dispatch => {
  const user = {
    firstname: data.firstname,
    surname: data.surname,
    email: data.email,
    roles: data.roles,
    code: data.code,
    company_code: data.company_code,
    location_code: data.location_code,
    telephone: data.telephone,
  };

  axios
    .post(`${BOUSER_BE_URL}/${_id}/update`, user)
    .then(res => {
      const { message } = res.data;

      dispatch({ type: SEND_SUCCESS_MESSAGE, payload: message });

      history.push('/bo-user/list');
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

export const updateBoUserEmail = (_id, user, history) => dispatch => {
  const { email } = user;
  const data = { id: _id, email };

  axios
    .post(`${AUTH_BE_URL}/resetaccount`, data)
    .then(res => {
      const { message } = res.data;

      dispatch({ type: SEND_SUCCESS_MESSAGE, payload: message });

      history.push('/bo-user/list');
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

// remove

// delete
export const deleteBoUserById = _id => dispatch => {
  axios
    .get(`${BOUSER_BE_URL}/${_id}/delete`)
    .then(res => {
      dispatch({ type: REMOVE_BO_USER_BY_ID, payload: _id });

      dispatch({
        type: SEND_SUCCESS_MESSAGE,
        payload: 'eliminato con successo',
      });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};
export const removeAllBoUser = () => dispatch => {
  dispatch({ type: REMOVE_ALL_BO_USER });
};

export const removeBoUser = () => dispatch => {
  dispatch({ type: REMOVE_BO_USER });
};

export const removeAllUserCode = () => dispatch => {
  dispatch({ type: REMOVE_ALL_USER_CODE });
};

export const removeBoUserToOption = () => dispatch => {
  dispatch({ type: REMOVE_BO_USER_TO_OPTION });
};
