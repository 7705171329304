import { isDataEmpty, isArrayEmpty } from './empty_helper';
import { isArray, isNumber } from './type_helper';

export const createArray = data => {
  return !isDataEmpty(data) ? [data] : [];
};

export const hasString = data => {
  return !isDataEmpty(data) ? data : '';
};

export const hasNumber = data => {
  return !isDataEmpty(data) && isNumber(data) ? data : 0;
};

export const hasArray = data => {
  return isArray(data) && !isArrayEmpty(data) ? data : [];
};

export const removeComma = data => {
  if (data.endsWith(', ')) {
    return data.substring(0, data.length - 2);
  }
};

export const companyFilter = company => {
  return !isDataEmpty(company)
    ? {
      company_code: company.company_code,
      company_name: company.company_name,
    }
    : {};
};

export const locationFilter = location => {
  return isArray(location) && !isArrayEmpty(location)
    ? location.map(l => {
      return { code: l.code, location_name: l.location_name };
    })
    : [];
};

export const categoryFilter = category => {
  return isArray(category) && !isArrayEmpty(category)
    ? category.map(c => {
      return c;
    })
    : [];
};

export const hasCategory = (categoryArray, category) => {
  let result = false;
  categoryArray.forEach(item => {
    if (item.name === category.name) {
      result = true
    }
  })
  return result
}

export const agentFilter = agent => {
  return isArray(agent) && !isArrayEmpty(agent)
    ? agent.map(a => {
      return { code: a.code, firstname: a.firstname, surname: a.surname };
    })
    : [];
};
