import React, { memo } from 'react';

import rbac_rule from './rbac-rule';

const checkAccess = (rules, role, action, data) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

const Can = memo(props => {
  const {
    role,
    perform,
    data,
    yes,
    no = () => (
      <h1 className="text-primary text-center">Cannot visit this page</h1>
    ),
  } = props;

  return checkAccess(rbac_rule, role, perform, data) ? yes() : no();
});

export default Can;
