import {
  GET_APP_ACTIVITY,
  REMOVE_ALL_APPACTIVITY,
} from '../actions/actionTypes';

const initialState = { appActivity: { loaded: false, data: [], totalRows: 0 } };

const appActivityReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_APP_ACTIVITY:
      return {
        ...state,
        appActivity: {
          loaded: true,
          data: payload.appActivity,
          totalRows: payload.total_rows,
        },
      };

    case REMOVE_ALL_APPACTIVITY:
      return {
        ...state,
        appActivity: { loaded: false, data: [], totalRows: 0 },
      };

    default:
      return state;
  }
};

export default appActivityReducer;
