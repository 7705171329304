import {
  GET_ALL_USER_SALE,
  GET_USER_SALE_BY_ID,
  REMOVE_ALL_USER_SALE,
  REMOVE_USER_SALE,
} from '../actions/actionTypes';

const initialState = {
  userSales: { loaded: false, data: [], totalRows: 0 },
  userSale: { loaded: false, data: {} },
};

const reportReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_USER_SALE:
      return {
        ...state,
        userSales: {
          loaded: true,
          data: payload.orders,
          totalRows: payload.total_rows,
        },
      };
    case GET_USER_SALE_BY_ID:
      return { ...state, userSale: { loaded: true, data: payload } };

    case REMOVE_ALL_USER_SALE:
      return {
        ...state,
        userSales: { loaded: false, data: [], totalRows: 0 },
      };
    case REMOVE_USER_SALE:
      return { ...state, userSale: { loaded: false, data: {} } };

    default:
      return state;
  }
};

export default reportReducer;
