export const isNumber = value => {
  return !isNaN(value);
};

export const isArray = value => {
  return Array.isArray(value);
};

export const isObject = value => {
  return typeof value === 'object';
};
