import {
  GET_ALL_JOURNEY,
  GET_JOURNEY_BY_ID,
  DELETE_JOURNEY,
  REMOVE_ALL_JOURNEY,
  REMOVE_JOURNEY,
} from '../actions/actionTypes';

const initialState = {
  journies: { loaded: false, data: [], totalRows: 0 },
  journey: { loaded: false, data: {} },
};

const journeyReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_JOURNEY:
      return {
        ...state,
        journies: {
          loaded: true,
          data: payload.journeys,
          totalRows: payload.total_rows,
        },
      };
    case GET_JOURNEY_BY_ID:
      return { ...state, journey: { loaded: true, data: payload } };

    case DELETE_JOURNEY:
      let journies = state.journies.data.filter(j => payload !== j._id);
      return {
        ...state,
        journies: {
          loaded: true,
          data: journies,
          totalRows: state.journies.totalRows - 1,
        },
      };

    case REMOVE_ALL_JOURNEY:
      return { ...state, journies: { loaded: false, data: [], totalRows: 0 } };
    case REMOVE_JOURNEY:
      return { ...state, journey: { loaded: false, data: {} } };

    default:
      return state;
  }
};

export default journeyReducer;
