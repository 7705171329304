import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';

const BreadCrumb = memo(props => {
  const { paths } = props;

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <NavLink to="/dashboard">Dashboard</NavLink>
        </li>
        {paths.map((path, key) => (
          <li key={key} className="breadcrumb-item" aria-current="page">
            <NavLink
              to={{
                pathname: path.to,
                state: { passed: path.passed ? path.passed : null },
              }}
            >
              {path.name}
            </NavLink>
          </li>
        ))}
      </ol>
    </nav>
  );
});

export default BreadCrumb;
