import { GET_ALL_TURNOVER, REMOVE_ALL_TURNOVER } from '../actions/actionTypes';

const initialState = { turnovers: { loaded: false, data: [], totalRows: 0 } };

const turnoverReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_TURNOVER:
      return {
        ...state,
        turnovers: {
          loaded: true,
          data: payload.turnovers,
          totalRows: payload.total_rows,
        },
      };

    case REMOVE_ALL_TURNOVER:
      return {
        ...state,
        turnovers: { loaded: false, data: [], totalRows: 0 },
      };

    default:
      return state;
  }
};

export default turnoverReducer;
