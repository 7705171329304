import {
  GET_ALL_TILE,
  GET_TILE_BY_ID,
  DELETE_TILE,
  REMOVE_ALL_TILE,
  REMOVE_TILE,
} from '../actions/actionTypes';

const initialState = {
  tiles: { loaded: false, data: [] },
  tile: { loaded: false, data: {} },
};

const tileReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_TILE:
      return { ...state, tiles: { loaded: true, data: payload } };
    case GET_TILE_BY_ID:
      return { ...state, tile: { loaded: true, data: payload } };

    case DELETE_TILE:
      const tiles = state.tiles.data.filter(t => payload !== t._id);
      return { ...state, tiles: { loaded: true, data: tiles } };

    case REMOVE_ALL_TILE:
      return { ...state, tiles: { loaded: false, data: [] } };
    case REMOVE_TILE:
      return { ...state, tile: { loaded: false, data: {} } };

    default:
      return state;
  }
};

export default tileReducer;
