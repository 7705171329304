import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import { sortArray } from '../../../helpers/array_helper';
import {
  setCompanyToOption,
  removeCompanyToOption,
} from '../../../actions/companyAction';

const FilterByCompany = memo(props => {
  const {
    company_code,
    handleFilter,
    company: { companyToOption },
    intl,
    setCompanyToOption,
    removeCompanyToOption,
  } = props;

  const [data, setData] = useState({
    company_data: [],
    loaded: false,
  });

  const { company_data, loaded } = data;

  useEffect(() => {
    setCompanyToOption();
  }, [setCompanyToOption]);

  useEffect(() => {
    const { loaded, data } = companyToOption;

    if (loaded) {
      sortArray(data, 'company_name');

      setData({ company_data: data, loaded: true });
    }
  }, [companyToOption]);

  useEffect(() => {
    return () => {
      removeCompanyToOption();
    };
  }, [removeCompanyToOption]);

  return !loaded ? (
    <FormattedMessage id="LOAD.FILTER" />
  ) : (
    <select
      name="company_code"
      className="form-control"
      value={company_code}
      onChange={handleFilter}
    >
      <option value="">
        {intl.formatMessage({
          id: 'FILTER.BY.COMPANY',
        })}
      </option>
      {company_data.map(c => {
        return (
          <option key={c.company_code} value={c.company_code}>
            {c.company_name}
          </option>
        );
      })}
    </select>
  );
});

FilterByCompany.propTypes = {
  company: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  company: state.app.company,
});

export default injectIntl(
  connect(mapStateToProps, { setCompanyToOption, removeCompanyToOption })(
    FilterByCompany
  )
);
