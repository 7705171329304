// Category
export const USER_CATEGORY_BE_URL = 'api/v1/categories';

// Company
export const COMPANY_BE_URL = 'api/v1/companies';

// Location
export const LOCATION_BE_URL = 'api/v1/locations';

// BoUser
export const BOUSER_BE_URL = 'api/v1/bousers';

// Auth
export const AUTH_BE_URL = 'api/v1/auth';

// Customer
export const CUSTOMER_BE_URL = 'api/v1/customers';

// App
export const APP_BE_URL = 'api/v1/apps';

// Template
export const TEMPLATE_BE_URL = 'api/v1/notifications/templates';

// Custom Template
export const CUSTOM_TEMPLATE_BE_URL = '/api/v1/customnotices';

// News
export const NEWS_BE_URL = '/api/v1/news';

// Luigi Comini Onlus
export const LUIGI_BE_URL = '/api/v1/luigi';

// Journey
export const JOURNEY_BE_URL = 'api/v1/journeys';

// Survey
export const SURVEY_BE_URL = 'api/v1/surveys';
export const SURVEY_EXPORT_URL = 'api/v1/feedbacks/survey/export';

// Target
export const TARGET_BE_URL = 'api/v1/targets';

// Csv
export const CSV_BE_URL = 'api/v1/imports';
export const CSV_DOWNLOAD_BE_URL = '/api/v1/fileinfos/download';

// Promotion Report
export const PROMOTION_REPORTS_BE_URL = '/api/v1/promotions/reports';
export const PROMOTION_REPORT_BE_URL = '/api/v1/promotions/report';

// Promotion
export const PROMOTION_BE_URL = '/api/v1/promotions';

// Award Option
export const AWARD_OPTION_BE_URL = '/api/v1/awardoptions';

// Gift Promotion
export const GIFT_PROMOTION_BE_URL = '/api/v1/giftpromotions';
export const GIFT_PROMOTION_TARGET_BE_URL = '/api/v1/promotargets';

// Reward Promotion
export const REWARD_PROMOTION_BE_URL = '/api/v1/rewardpromotions';

// Turnover
export const TURNOVER_BE_URL = 'api/v1/turnovers';

// User sale
export const USER_SALE_BE_URL = 'api/v1/orders';

// Group
export const GROUP_BE_URL = 'api/v1/groups';
