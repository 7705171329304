import { GET_ALL_CSV, REMOVE_ALL_CSV } from '../actions/actionTypes';

const initialState = {
  csvs: { loaded: false, data: [], totalRows: 0 },
};

const csvReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_CSV:
      return {
        ...state,
        csvs: {
          loaded: true,
          data: payload.importData,
          totalRows: payload.total_rows,
        },
      };

    case REMOVE_ALL_CSV:
      return {
        ...state,
        csvs: { loaded: false, data: {}, totalRows: 0 },
      };

    default:
      return state;
  }
};

export default csvReducer;
