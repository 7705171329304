/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from './app/axios';
import { setupAxios } from './_metronic';
import store, { persistor } from './app/store/store';
import App from './App';
import './index.scss'; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './_metronic/_assets/plugins/line-awesome/css/line-awesome.css';
import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = 'http://localhost:3000/';

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
// /* const mock = */ mockAxios(axios);

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios, store);

const Layout = React.lazy(() => import('./_metronic/layout/Layout.js'));

// function retry(fn, retriesLeft = 5, interval = 1000) {
//   return new Promise((resolve, reject) => {
//     fn()
//       .then(resolve)
//       .catch(error => {
//         setTimeout(() => {
//           if (retriesLeft === 1) {
//             // reject('maximum retries exceeded');
//             reject(error);
//             return;
//           }

//           // Passing on "reject" is the important part
//           retry(fn, retriesLeft - 1, interval).then(resolve, reject);
//         }, interval);
//       });
//   });
// }

// const Layout = React.lazy(() =>
//   retry(() => import('./_metronic/layout/Layout.js'))
// );

// function componentLoader() {
//   return new Promise((resolve, reject) => {
//     import("./_metronic/layout/Layout.js")
//       .then(resolve)
//       .catch((error) => {
//         // let us retry after 1500 ms
//         setTimeout(() => {
//           console.log('metronic layout error')
//           return;

//         }, 1500);
//       });
//   });
// }

// const Layout = React.lazy(() => componentLoader());

ReactDOM.render(
  <App
    store={store}
    Layout={Layout}
    persistor={persistor}
    basename={PUBLIC_URL}
  />,
  document.getElementById('root')
);
