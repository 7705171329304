import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import { LayoutSplashScreen } from '../../../../_metronic';

const QuickStartDocs = lazy(() => import('./QuickStartDocs'));
const DeploymentDocs = lazy(() => import('./DeploymentDocs'));
const OverviewDocs = lazy(() => import('./OverviewDocs'));
const I18nDocs = lazy(() => import('./I18nDocs'));
const MockBackendDocs = lazy(() => import('./MockBackendDocs'));
const CreateAPageDocs = lazy(() => import('./CreateAPageDocs'));
const UseADemo = lazy(() => import('./UseADemo'));
const RequestToServerDocs = lazy(() => import('./RequestToServerDocs'));
const CreateAReducerDocs = lazy(() => import('./CreateAReducerDocs'));
const CanComponentDocs = lazy(() => import('./CanComponentDocs'));

export default function DocsPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Route path="/docs/quick-start" component={QuickStartDocs} />
        <Route path="/docs/overview" component={OverviewDocs} />
        <Route path="/docs/deployment" component={DeploymentDocs} />
        <Route path="/docs/i18n" component={I18nDocs} />
        <Route path="/docs/mock-backend" component={MockBackendDocs} />
        <Route path="/docs/create-a-page" component={CreateAPageDocs} />
        <Route path="/docs/use-a-demo" component={UseADemo} />
        <Route path="/docs/request-to-server" component={RequestToServerDocs} />
        <Route path="/docs/create-a-reducer" component={CreateAReducerDocs} />
        <Route path="/docs/use-rbac" component={CanComponentDocs} />
      </Switch>
    </Suspense>
  );
}
