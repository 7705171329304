import axios from '../axios';
import { AUTH_BE_URL, BOUSER_BE_URL } from '../helpers/api_helper';
import { SEND_SUCCESS_MESSAGE } from './actionTypes';

export function login(email, password) {
  return axios.post(`${AUTH_BE_URL}/bouser/login`, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(`${AUTH_BE_URL}/register`, {
    email,
    fullname,
    username,
    password,
  });
}

export function requestPassword(email) {
  return axios.post(`${AUTH_BE_URL}/bouser/forget/password`, { email });
}

export function resetPassword(code, password, token) {
  return axios.post(
    `${AUTH_BE_URL}/bouser/reset/password?forget_password_token=${token}`,
    { code, password }
  );
}

export function changeBoUserPassword(oldPassword, newPassword) {
  return axios.post(`/${AUTH_BE_URL}/bouser/update/password`, {
    password: newPassword,
    oldpassword: oldPassword,
  });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(`${BOUSER_BE_URL}/me`);
}

export const redirectUser = props => dispatch => {
  dispatch({
    type: SEND_SUCCESS_MESSAGE,
    payload: 'Password Reset Successfully',
  });
};
