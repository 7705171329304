import axios from '../axios';
import { SEND_SUCCESS_MESSAGE, GET_ERRORS } from './actionTypes';

import { errorHelper } from '../helpers/error_helper';

export const importCompanyCSVFile = csv => dispatch => {
  return axios
    .post('/api/v1/file/uploadCompany', csv)
    .then(res => {
      dispatch({ type: SEND_SUCCESS_MESSAGE, payload: res.data.message });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

export const importLocationCSVFile = csv => dispatch => {
  return axios
    .post('/api/v1/file/uploadLocation', csv)
    .then(res => {
      dispatch({ type: SEND_SUCCESS_MESSAGE, payload: res.data.message });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

export const importBoUserCSVFile = csv => dispatch => {
  return axios
    .post('/api/v1/file/uploadBoUser', csv)
    .then(res => {
      dispatch({ type: SEND_SUCCESS_MESSAGE, payload: res.data.message });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

export const importCustomerCSVFile = csv => dispatch => {
  return axios
    .post('/api/v1/file/uploadCustomer', csv)
    .then(res => {
      dispatch({ type: SEND_SUCCESS_MESSAGE, payload: res.data.message });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

export const importTargetCSVFile = csv => dispatch => {
  return axios
    .post('/api/v1/file/uploadTarget', csv)
    .then(res => {
      dispatch({ type: SEND_SUCCESS_MESSAGE, payload: res.data.message });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

export const importRewardTargetCSVFile = csv => dispatch => {
  return axios
    .post('/api/v1/file/uploadRewardTarget', csv)
    .then(res => {
      dispatch({ type: SEND_SUCCESS_MESSAGE, payload: res.data.message });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

export const importTurnOverCSVFile = csv => dispatch => {
  return axios
    .post('/api/v1/file/uploadTurnover', csv)
    .then(res => {
      dispatch({ type: SEND_SUCCESS_MESSAGE, payload: res.data.message });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

export const importUserSalesCSVFile = csv => dispatch => {
  return axios
    .post('/api/v1/file/uploadOrder', csv)
    .then(res => {
      dispatch({ type: SEND_SUCCESS_MESSAGE, payload: res.data.message });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

export const importPromotionCSVFile = csv => dispatch => {
  return axios
    .post('/api/v1/file/uploadPromotion', csv)
    .then(res => {
      dispatch({ type: SEND_SUCCESS_MESSAGE, payload: res.data.message });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};
