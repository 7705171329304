import { combineReducers } from 'redux';

import alertReducer from './alertReducer';
import appReducer from './appReducer';
import appUserReducer from './appUserReducer';
import boUserReducer from './boUserReducer';
import companyReducer from './companyReducer';
import csvReducer from './csvReducer';
import emailTemplateReducer from './emailTemplateReducer';
import groupReducer from './groupReducer';
import journeyReducer from './journeyReducer';
import locationReducer from './locationReducer';
import newsReducer from './newsReducer';
import promotionReducer from './promotionReducer';
import promotionReportReducer from './promotionReportReducer';
import pushTemplateReducer from './pushTemplateReducer';
import reportReducer from './reportReducer';
import surveyReducer from './surveyReducer';
import targetReducer from './targetReducer';
import tileReducer from './tileReducer';
import turnoverReducer from './turnoverReducer';
import userCategoryReducer from './userCategoryReducer';
import luigiReducer from './luigiReducer';
import appActivityReducer from './appActivityReducer';

export default combineReducers({
  alert: alertReducer,
  app: appReducer,
  appUser: appUserReducer,
  boUser: boUserReducer,
  category: userCategoryReducer,
  company: companyReducer,
  csv: csvReducer,
  emailTemplate: emailTemplateReducer,
  group: groupReducer,
  journey: journeyReducer,
  location: locationReducer,
  news: newsReducer,
  promotion: promotionReducer,
  promotionReport: promotionReportReducer,
  pushTemplate: pushTemplateReducer,
  report: reportReducer,
  survey: surveyReducer,
  target: targetReducer,
  tile: tileReducer,
  turnover: turnoverReducer,
  luigi: luigiReducer,
  appActivity: appActivityReducer,
});
