export const arrayReducer = (data, property) => {
  return data.reduce((acc, obj) => {
    let key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};

export const arrayValues = data => {
  return data.map(d => {
    return Object.values(d);
  });
};

export const sortArray = (data, property) => {
  return data.sort((a, b) => {
    let x = a[property].toLowerCase();
    let y = b[property].toLowerCase();

    if (x < y) {
      return -1;
    } else if (x > y) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const setCompanySitesForCustomTemplate = (
  global_topics,
  company_code,
  location_code,
  role,
  category
) => {
  let company_sites = [];

  switch (global_topics) {
    case 'everyone':
    case 'admin':
    case 'company_managers':
      company_sites = [];
      break;
    case 'company_users':
      company_sites = [{ company_code, role }];
      break;
    case 'area_managers':
    case 'site_managers':
    case 'agents':
      company_sites = [{ company_code, location_code, role }];
      break;
    case 'customers':
      company_sites = [{ company_code, location_code, role, category }];
      break;
    default:
      company_sites = [];
      break;
  }

  return company_sites;
};
