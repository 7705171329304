import {
  GET_ALL_SURVEY,
  GET_SURVEY_BY_ID,
  GET_ALL_QUESTION,
  GET_QUESTION_BY_ID,
  DELETE_SURVEY,
  DELETE_QUESTION,
  REMOVE_ALL_SURVEY,
  REMOVE_SURVEY,
  REMOVE_ALL_QUESTION,
  REMOVE_QUESTION,
} from '../actions/actionTypes';

const initialState = {
  surveys: { loaded: false, data: [], totalRows: 0 },
  survey: { loaded: false, data: {} },
  questions: { loaded: false, data: [] },
  question: { loaded: false, data: {} },
};

const surveyReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_SURVEY:
      return {
        ...state,
        surveys: {
          loaded: true,
          data: payload.surveys,
          totalRows: payload.total_rows,
        },
      };
    case GET_SURVEY_BY_ID:
      return { ...state, survey: { loaded: true, data: payload } };
    case GET_ALL_QUESTION:
      return { ...state, questions: { loaded: true, data: payload } };
    case GET_QUESTION_BY_ID:
      return { ...state, question: { loaded: true, data: payload } };

    case DELETE_SURVEY:
      let surveys = state.surveys.data.filter(s => payload !== s._id);
      return {
        ...state,
        surveys: {
          loaded: true,
          data: surveys,
          totalRows: state.surveys.totalRows - 1,
        },
      };
    case DELETE_QUESTION:
      let questions = state.questions.data.filter(q => payload !== q._id);
      return {
        ...state,
        questions: { loaded: true, data: questions },
      };

    case REMOVE_ALL_SURVEY:
      return { ...state, surveys: { loaded: false, data: [] } };
    case REMOVE_SURVEY:
      return { ...state, survey: { loaded: false, data: {} } };
    case REMOVE_ALL_QUESTION:
      return { ...state, questions: { loaded: false, data: [] } };
    case REMOVE_QUESTION:
      return { ...state, question: { loaded: false, data: {} } };

    default:
      return state;
  }
};

export default surveyReducer;
