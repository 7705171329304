import {
  GET_ALL_LOCATION,
  GET_LOCATION_BY_ID,
  SET_LOCATION_TO_OPTION,
  DELETE_LOCATION,
  REMOVE_ALL_LOCATION,
  REMOVE_LOCATION,
  REMOVE_COMPANY_TO_OPTION,
} from '../actions/actionTypes';

const initialState = {
  locations: { loaded: false, data: [], totalRows: 0 },
  location: { loaded: false, data: {} },
  locationToOption: { loaded: false, data: [], selectedCompany: '' },
};

const locationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_LOCATION:
      return {
        ...state,
        locations: {
          loaded: true,
          data: payload.locations,
          totalRows: payload.total_rows,
        },
      };
    case GET_LOCATION_BY_ID:
      return { ...state, location: { loaded: true, data: payload } };
    case SET_LOCATION_TO_OPTION:
      return {
        ...state,
        locationToOption: {
          loaded: true,
          data: payload.locations,
          selectedCompany: payload.company_code,
        },
      };

    case DELETE_LOCATION:
      let locations = state.locations.data.filter(c => payload !== c._id);
      return {
        ...state,
        locations: {
          loaded: true,
          data: locations,
          totalRows: state.locations.totalRows - 1,
        },
      };

    case REMOVE_ALL_LOCATION:
      return {
        ...state,
        locations: { loaded: false, data: [], totalRows: 0 },
      };
    case REMOVE_LOCATION:
      return { ...state, location: { loaded: false, data: [] } };
    case REMOVE_COMPANY_TO_OPTION:
      return {
        ...state,
        locationToOption: { loaded: false, data: [], selectedCompany: '' },
      };

    default:
      return state;
  }
};

export default locationReducer;
