import {
  GET_ALL_NEWS,
  GET_NEWS_BY_ID,
  PUBLISH_NEWS,
  DELETE_NEWS,
  REMOVE_ALL_NEWS,
  REMOVE_NEWS,
} from '../actions/actionTypes';

const initialState = {
  news: { loaded: false, data: [], totalRows: 0 },
  oneNews: { loaded: false, data: {} },
};

const newsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_NEWS:
      return {
        ...state,
        news: {
          loaded: true,
          data: payload.news,
          totalRows: payload.total_rows,
        },
      };
    case GET_NEWS_BY_ID:
      return { ...state, oneNews: { loaded: true, data: payload } };

    case PUBLISH_NEWS:
      let news = state.news.data.map(n => {
        if (payload._id === n._id) {
          n.publication_date = payload.publication_date;
        }
        return n;
      });
      return { ...state, news: { loaded: true, data: news } };
    case DELETE_NEWS:
      news = state.news.data.filter(n => payload !== n._id);
      return {
        ...state,
        news: {
          loaded: true,
          data: news,
          totalRows: state.news.totalRows - 1,
        },
      };

    case REMOVE_ALL_NEWS:
      return { ...state, news: { loaded: false, data: [] } };
    case REMOVE_NEWS:
      return { ...state, oneNews: { loaded: false, data: {} } };

    default:
      return state;
  }
};

export default newsReducer;
