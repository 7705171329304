import {
  GET_ERRORS,
  SEND_SUCCESS_MESSAGE,
  REMOVE_ERRORS,
  REMOVE_MESSAGE,
} from '../actions/actionTypes';

const initialState = { message: '', error: [] };

const alertReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ERRORS:
      return { ...state, error: payload };
    case SEND_SUCCESS_MESSAGE:
      return { ...state, message: payload };

    case REMOVE_ERRORS:
      return { ...state, error: [] };
    case REMOVE_MESSAGE:
      return { ...state, message: '' };

    default:
      return state;
  }
};

export default alertReducer;
