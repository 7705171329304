import { isArray } from './type_helper';

export const isDataEmpty = value => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
};

export const isArrayEmpty = value => {
  if (isDataEmpty(value)) {
    return true;
  }

  return isArray(value) && value.length === 0;
};
