import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { LayoutSplashScreen } from '../../../_metronic';
import AppActivityPage from './appactivity/AppActivityPage';
import Builder from './Builder';
import DocsPage from './docs/DocsPage';

const Dashboard = lazy(() => import('./Dashboard/Dashboard'));
const FirstLogin = lazy(() => import('./FirstLogin'));

const UserCategoryPage = lazy(() => import('./user-category/UserCategoryPage'));
const UserCategoryCreatePage = lazy(() =>
  import('./user-category/UserCategoryCreatePage')
);

const CompanyPage = lazy(() => import('./company/CompanyPage'));
const CompanyCreatePage = lazy(() => import('./company/CompanyCreatePage'));
const CompanyEditPage = lazy(() => import('./company/CompanyEditPage'));

const LocationPage = lazy(() => import('./location/LocationPage'));
const LocationCreatePage = lazy(() => import('./location/LocationCreatePage'));
const LocationEditPage = lazy(() => import('./location/LocationEditPage'));

const BoUserPage = lazy(() => import('./bouser/BoUserPage'));
const BoUserCreatePage = lazy(() => import('./bouser/BoUserCreatePage'));
const BoUserEditPage = lazy(() => import('./bouser/BoUserEditPage'));
const BoUserEmailEditPage = lazy(() => import('./bouser/BoUserEmailEditPage'));

const AppUserPage = lazy(() => import('./app-user/AppUserPage'));
const AppUserCreatePage = lazy(() => import('./app-user/AppUserCreatePage'));
const AppUserEditPage = lazy(() => import('./app-user/AppUserEditPage'));

const AppPage = lazy(() => import('./app/AppPage'));

const TilePage = lazy(() => import('./tile/TilePage'));
const TileCreatePage = lazy(() => import('./tile/TileCreatePage'));
const TileEditPage = lazy(() => import('./tile/TileEditPage'));

const EmailTemplatePage = lazy(() => import('./email/EmailTemplatePage'));
const EmailTemplateCreatePage = lazy(() =>
  import('./email/EmailTemplateCreatePage')
);
const EmailTemplateEditPage = lazy(() =>
  import('./email/EmailTemplateEditPage')
);
const CustomEmailPage = lazy(() => import('./email/custom/CustomEmailPage'));
const CustomEmailCreatePage = lazy(() =>
  import('./email/custom/CustomEmailCreatePage')
);
const CustomEmailEditPage = lazy(() =>
  import('./email/custom/CustomEmailEditPage')
);

const PushNotificationTemplatePage = lazy(() =>
  import('./push-notification/PushNotificationTemplatePage')
);
const PushNotificationTemplateCreatePage = lazy(() =>
  import('./push-notification/PushNotificationTemplateCreatePage')
);
const PushNotificationTemplateEditPage = lazy(() =>
  import('./push-notification/PushNotificationTemplateEditPage')
);
const CustomPushNotificationTemplatePage = lazy(() =>
  import('./push-notification/custom/CustomPushNotificationTemplatePage')
);
const CustomPushNotificationTemplateCreatePage = lazy(() =>
  import('./push-notification/custom/CustomPushNotificationTemplateCreatePage')
);
const CustomPushNotificationTemplateEditPage = lazy(() =>
  import('./push-notification/custom/CustomPushNotificationTemplateEditPage')
);

const GroupPage = lazy(() => import('./group/GroupPage'));
const GroupCreatePage = lazy(() => import('./group/GroupCreatePage'));
const GroupEditPage = lazy(() => import('./group/GroupEditPage'));

const NewsPage = lazy(() => import('./news/NewsPage'));
const NewsCreatePage = lazy(() => import('./news/NewsCreatePage'));
const NewsEditPage = lazy(() => import('./news/NewsEditPage'));

const LuigiPage = lazy(() => import('./luigicominionlus/LuigiPage'));
const LuigiCreatePage = lazy(() =>
  import('./luigicominionlus/LuigiCreatePage')
);
const LuigiEditPage = lazy(() => import('./luigicominionlus/LuigiEditPage'));

const JourneyPage = lazy(() => import('./journey/JourneyPage'));
const JourneyCreatePage = lazy(() => import('./journey/JourneyCreatePage'));
const JourneyEditPage = lazy(() => import('./journey/JourneyEditPage'));

const SurveyPage = lazy(() => import('./survey/SurveyPage'));
const SurveyCreatePage = lazy(() => import('./survey/SurveyCreatePage'));
const SurveyEditPage = lazy(() => import('./survey/SurveyEditPage'));
const QuestionPage = lazy(() => import('./survey/question/QuestionPage'));
const QuestionCreatePage = lazy(() =>
  import('./survey/question/QuestionCreatePage')
);
const QuestionEditPage = lazy(() =>
  import('./survey/question/QuestionEditPage')
);

const TargetPage = lazy(() => import('./target/TargetPage'));
const TargetViewPage = lazy(() => import('./target/TargetViewPage'));

const CsvPage = lazy(() => import('./csv/CsvPage'));

const CompaniesPromotionReportPage = lazy(() =>
  import('./promotion/report/CompaniesPromotionReportPage')
);
const CompanyPromotionReportPage = lazy(() =>
  import('./promotion/report/CompanyPromotionReportPage')
);
const AgentsPromotionReportPage = lazy(() =>
  import('./promotion/report/AgentsPromotionReportPage')
);

const PromotionPage = lazy(() => import('./promotion/PromotionPage'));
const PromotionCreatePage = lazy(() =>
  import('./promotion/PromotionCreatePage')
);

const AwardOptionPage = lazy(() =>
  import('./promotion/award-option/AwardOptionPage')
);
const AwardOptionCreatePage = lazy(() =>
  import('./promotion/award-option/AwardOptionCreatePage')
);
const AwardOptionEditPage = lazy(() =>
  import('./promotion/award-option/AwardOptionEditPage')
);

const GiftPromotionViewPage = lazy(() =>
  import('./gift-promotion/GiftPromotionViewPage')
);
const GiftViewPage = lazy(() => import('./gift-promotion/gift/GiftViewPage'));
const GiftPromotionAdditionalAwardListViewPage = lazy(() =>
  import(
    './gift-promotion/additional-award/GiftPromotionAdditionalAwardListViewPage'
  )
);
const GiftPromotionAdditionalAwardViewPage = lazy(() =>
  import(
    './gift-promotion/additional-award/GiftPromotionAdditionalAwardViewPage'
  )
);
const GiftPromotionEditPage = lazy(() =>
  import('./gift-promotion/GiftPromotionEditPage')
);
const PromotionGiftListEditPage = lazy(() =>
  import('./gift-promotion/PromotionGiftListEditPage')
);
const GiftCreatePage = lazy(() =>
  import('./gift-promotion/gift/GiftCreatePage')
);
const GiftEditPage = lazy(() => import('./gift-promotion/gift/GiftEditPage'));
const GiftPromotionAdditionalAwardListEditPage = lazy(() =>
  import(
    './gift-promotion/additional-award/GiftPromotionAdditionalAwardListEditPage'
  )
);
const GiftPromotionAdditionalAwardCreatePage = lazy(() =>
  import(
    './gift-promotion/additional-award/GiftPromotionAdditionalAwardCreatePage'
  )
);
const GiftPromotionAdditionalAwardEditPage = lazy(() =>
  import(
    './gift-promotion/additional-award/GiftPromotionAdditionalAwardEditPage'
  )
);

const RewardPromotionViewPage = lazy(() =>
  import('./reward-promotion/RewardPromotionViewPage')
);
const RewardPromotionAdditionalAwardListViewPage = lazy(() =>
  import(
    './reward-promotion/additional-award/RewardPromotionAdditionalAwardListViewPage'
  )
);
const RewardPromotionAdditionalAwardViewPage = lazy(() =>
  import(
    './reward-promotion/additional-award/RewardPromotionAdditionalAwardViewPage'
  )
);
const RewardPromotionEditPage = lazy(() =>
  import('./reward-promotion/RewardPromotionEditPage')
);
const PromotionTargetListEditPage = lazy(() =>
  import('./reward-promotion/PromotionTargetListEditPage')
);
const RewardPromotionAdditionalAwardListEditPage = lazy(() =>
  import(
    './reward-promotion/additional-award/RewardPromotionAdditionalAwardListEditPage'
  )
);
const RewardPromotionAdditionalAwardCreatePage = lazy(() =>
  import(
    './reward-promotion/additional-award/RewardPromotionAdditionalAwardCreatePage'
  )
);
const RewardPromotionAdditionalAwardEditPage = lazy(() =>
  import(
    './reward-promotion/additional-award/RewardPromotionAdditionalAwardEditPage'
  )
);

const TurnoverPage = lazy(() => import('./turnover/TurnoverPage'));

const UserSalesReportPage = lazy(() => import('./report/UserSalesReportPage'));
const UserSaleReportViewPage = lazy(() =>
  import('./report/UserSaleReportViewPage')
);

const InfoPage = lazy(() => import('./InfoPage'));

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/builder" component={Builder} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/firstlogin" component={FirstLogin} />
        {/* User Category Route */}
        <Route path="/category/list" exact component={UserCategoryPage} />
        <Route
          path="/category/create"
          exact
          component={UserCategoryCreatePage}
        />
        {/* Company Route */}
        <Route path="/company/list" exact component={CompanyPage} />
        <Route path="/company/create" exact component={CompanyCreatePage} />
        <Route
          path="/company/edit/:company_id"
          exact
          component={CompanyEditPage}
        />
        {/* Location Route */}
        <Route path="/location/list" exact component={LocationPage} />
        <Route path="/location/create" exact component={LocationCreatePage} />
        <Route
          path="/location/edit/:location_id"
          exact
          component={LocationEditPage}
        />
        {/* BoUser Route */}
        <Route path="/bo-user/list" exact component={BoUserPage} />
        <Route path="/bo-user/create" exact component={BoUserCreatePage} />
        <Route
          path="/bo-user/edit/:bouser_id"
          exact
          component={BoUserEditPage}
        />
        <Route
          path="/bo-user/edit/:bouser_id/email"
          exact
          component={BoUserEmailEditPage}
        />
        {/* AppUser Route */}
        <Route path="/app-user/list" exact component={AppUserPage} />
        <Route path="/app-user/create" exact component={AppUserCreatePage} />
        <Route
          path="/app-user/edit/:appuser_id"
          exact
          component={AppUserEditPage}
        />
        {/* Apps Route */}
        <Route path="/app/list" exact component={AppPage} />
        {/* Apps Route */}
        <Route path="/app/:app_id/tile/list" exact component={TilePage} />
        <Route
          path="/app/:app_id/tile/create"
          exact
          component={TileCreatePage}
        />
        <Route
          path="/app/:app_id/tile/edit/:tile_id"
          exact
          component={TileEditPage}
        />
        {/* Email Template Route */}
        <Route
          path="/email-template/list"
          exact
          component={EmailTemplatePage}
        />
        <Route
          path="/email-template/create"
          exact
          component={EmailTemplateCreatePage}
        />
        <Route
          path="/email-template/edit/:template_id"
          exact
          component={EmailTemplateEditPage}
        />
        {/* Custom Email Template Route */}
        <Route
          path="/email-template/custom/list"
          exact
          component={CustomEmailPage}
        />
        <Route
          path="/email-template/custom/create"
          exact
          component={CustomEmailCreatePage}
        />
        <Route
          path="/email-template/custom/edit/:template_id"
          exact
          component={CustomEmailEditPage}
        />
        {/* PushNotification Template Route */}
        <Route
          path="/push-template/list"
          exact
          component={PushNotificationTemplatePage}
        />
        <Route
          path="/push-template/create"
          exact
          component={PushNotificationTemplateCreatePage}
        />
        <Route
          path="/push-template/edit/:template_id"
          exact
          component={PushNotificationTemplateEditPage}
        />
        {/* Custom PushNotification Template Route */}
        <Route
          path="/push-template/custom/list"
          exact
          component={CustomPushNotificationTemplatePage}
        />
        <Route
          path="/push-template/custom/create"
          exact
          component={CustomPushNotificationTemplateCreatePage}
        />
        <Route
          path="/push-template/custom/edit/:template_id"
          exact
          component={CustomPushNotificationTemplateEditPage}
        />
        {/* Group Route */}
        <Route path="/group/list" exact component={GroupPage} />
        <Route path="/group/create" exact component={GroupCreatePage} />
        <Route path="/group/edit/:group_id" exact component={GroupEditPage} />
        {/* News Route */}
        <Route path="/news/list" exact component={NewsPage} />
        <Route path="/news/create" exact component={NewsCreatePage} />
        <Route path="/news/edit/:news_id" exact component={NewsEditPage} />
        {/* Luigi Comini Onlus Route */}
        <Route path="/luigi/list" exact component={LuigiPage} />
        <Route path="/luigi/create" exact component={LuigiCreatePage} />
        <Route path="/luigi/edit/:luigi_id" exact component={LuigiEditPage} />
        {/* Journey Route */}
        <Route path="/journey/list" exact component={JourneyPage} />
        <Route path="/journey/create" exact component={JourneyCreatePage} />
        <Route
          path="/journey/edit/:journey_id"
          exact
          component={JourneyEditPage}
        />
        {/* Survey Route */}
        <Route path="/survey/list" exact component={SurveyPage} />
        <Route path="/survey/create" exact component={SurveyCreatePage} />
        <Route
          path="/survey/edit/:survey_id"
          exact
          component={SurveyEditPage}
        />
        <Route
          path="/survey/:survey_id/question/list"
          exact
          component={QuestionPage}
        />
        <Route
          path="/survey/:survey_id/question/create"
          exact
          component={QuestionCreatePage}
        />
        <Route
          path="/survey/:survey_id/question/edit/:question_id"
          exact
          component={QuestionEditPage}
        />
        {/* Target Route */}
        <Route path="/target/list" exact component={TargetPage} />
        <Route
          path="/target/view/:target_id"
          exact
          component={TargetViewPage}
        />
        {/* Csv Route */}
        <Route path="/csv/list/:record_type" exact component={CsvPage} />
        {/* Promotion Report */}
        <Route
          path="/promotion-report/company/list"
          exact
          component={CompaniesPromotionReportPage}
        />
        <Route
          path="/promotion-report/company/:code"
          exact
          component={CompanyPromotionReportPage}
        />
        <Route
          path="/promotion-report/agent/list"
          exact
          component={AgentsPromotionReportPage}
        />
        {/* Promotion Route */}
        <Route path="/promotion/list" exact component={PromotionPage} />
        <Route path="/promotion/create" exact component={PromotionCreatePage} />
        {/* Award Route */}
        <Route
          path="/promotion/award-option/list"
          exact
          component={AwardOptionPage}
        />
        <Route
          path="/promotion/award-option/create"
          exact
          component={AwardOptionCreatePage}
        />
        <Route
          path="/promotion/award-option/edit/:award_id"
          exact
          component={AwardOptionEditPage}
        />
        {/* Gift Promotion Route */}
        <Route
          path="/promotion/view/:promotion_id/gift"
          exact
          component={GiftPromotionViewPage}
        />
        <Route
          path="/promotion/view/:promotion_id/gift/:gift_id"
          exact
          component={GiftViewPage}
        />
        <Route
          path="/promotion/view/:promotion_id/gift/additional-award/list"
          exact
          component={GiftPromotionAdditionalAwardListViewPage}
        />
        <Route
          path="/promotion/view/:promotion_id/gift/additional-award/view/:award_id"
          exact
          component={GiftPromotionAdditionalAwardViewPage}
        />
        <Route
          path="/promotion/edit/:promotion_id/gift"
          exact
          component={GiftPromotionEditPage}
        />
        <Route
          path="/promotion/edit/:promotion_id/gift/list"
          exact
          component={PromotionGiftListEditPage}
        />
        <Route
          path="/promotion/edit/:promotion_id/gift/create"
          exact
          component={GiftCreatePage}
        />
        <Route
          path="/promotion/edit/:promotion_id/gift/edit/:gift_id"
          exact
          component={GiftEditPage}
        />
        <Route
          path="/promotion/edit/:promotion_id/gift/additional-award/list"
          exact
          component={GiftPromotionAdditionalAwardListEditPage}
        />
        <Route
          path="/promotion/edit/:promotion_id/gift/additional-award/create"
          exact
          component={GiftPromotionAdditionalAwardCreatePage}
        />
        <Route
          path="/promotion/edit/:promotion_id/gift/additional-award/edit/:award_id"
          exact
          component={GiftPromotionAdditionalAwardEditPage}
        />
        {/* Reward Promotion Route */}
        <Route
          path="/promotion/view/:promotion_id/reward"
          exact
          component={RewardPromotionViewPage}
        />
        <Route
          path="/promotion/view/:promotion_id/reward/additional-award/list"
          exact
          component={RewardPromotionAdditionalAwardListViewPage}
        />
        <Route
          path="/promotion/view/:promotion_id/reward/additional-award/view/:award_id"
          exact
          component={RewardPromotionAdditionalAwardViewPage}
        />
        <Route
          path="/promotion/edit/:promotion_id/reward"
          exact
          component={RewardPromotionEditPage}
        />
        <Route
          path="/promotion/edit/:promotion_id/reward/list"
          exact
          component={PromotionTargetListEditPage}
        />
        <Route
          path="/promotion/edit/:promotion_id/reward/additional-award/list"
          exact
          component={RewardPromotionAdditionalAwardListEditPage}
        />
        <Route
          path="/promotion/edit/:promotion_id/reward/additional-award/create"
          exact
          component={RewardPromotionAdditionalAwardCreatePage}
        />
        <Route
          path="/promotion/edit/:promotion_id/reward/additional-award/edit/:award_id"
          exact
          component={RewardPromotionAdditionalAwardEditPage}
        />
        {/* Turnover Route */}
        <Route path="/turnover/list" exact component={TurnoverPage} />
        {/* User sale Route */}
        <Route path="/user-sale/list" exact component={UserSalesReportPage} />
        <Route
          path="/user-sale/view/:report_id"
          exact
          component={UserSaleReportViewPage}
        />

        {/* Turnover Route */}
        <Route path="/appactivity" exact component={AppActivityPage} />

        <Route path="/success" component={InfoPage} />

        <Route path="/docs" component={DocsPage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
