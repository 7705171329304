import React, { memo } from 'react';

const TableSearchbar = memo(props => {
  const { search } = props;
  const value = props.value;

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="input-group" id="adv-search">
          <input
            type="text"
            className="form-control"
            onChange={search}
            value={value}
          />
          <div className="input-group-btn">
            <div className="btn-group" role="group">
              <button type="button" className="btn btn-primary">
                <i className="la la-search" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default TableSearchbar;
