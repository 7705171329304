import axios from '../axios';
import { COMPANY_BE_URL } from '../helpers/api_helper';
import {
  GET_ERRORS,
  SEND_SUCCESS_MESSAGE,
  GET_ALL_COMPANY,
  GET_COMPANY_BY_ID,
  SET_COMPANY_TO_OPTION,
  REMOVE_ALL_COMPANY,
  REMOVE_COMPANY,
  REMOVE_COMPANY_TO_OPTION,
  DELETE_COMPANY_BY_ID,
} from './actionTypes';

import { hasString } from '../helpers/data_helper';
import { errorHelper } from '../helpers/error_helper';

// get
export const getAllCompany = () => dispatch => {
  axios
    .get(COMPANY_BE_URL)
    .then(res => {
      let {
        data: { companies },
      } = res.data;

      companies = companies.map(c => {
        return {
          _id: hasString(c._id),
          company_code: hasString(c.company_code),
          company_name: hasString(c.company_name),
        };
      });

      dispatch({ type: GET_ALL_COMPANY, payload: companies });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

export const setCompanyToOption = () => dispatch => {
  axios
    .get(COMPANY_BE_URL)
    .then(res => {
      let {
        data: { companies },
      } = res.data;

      companies = companies.map(c => {
        return {
          company_code: hasString(c.company_code),
          company_name: hasString(c.company_name),
        };
      });

      dispatch({ type: SET_COMPANY_TO_OPTION, payload: companies });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

export const getCompanyById = _id => dispatch => {
  axios
    .get(`${COMPANY_BE_URL}/${_id}`)
    .then(res => {
      let {
        data: { company },
      } = res.data;

      company = {
        company_name: hasString(company.company_name),
        company_code: hasString(company.company_code),
        description: hasString(company.description),
        logo: hasString(company.logo),
        logo_square: hasString(company.logo_square),
        header_image: hasString(company.header_image),
      };

      dispatch({ type: GET_COMPANY_BY_ID, payload: company });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

// post
export const createCompany = (data, history) => dispatch => {
  const company = {
    company_name: data.company_name,
    company_code: data.company_code,
    description: data.description,
    logo: data.logo,
    logo_square: data.logo_square,
    header_image: data.header_image,
  };

  axios
    .post(COMPANY_BE_URL, company)
    .then(res => {
      const { message } = res.data;

      dispatch({ type: SEND_SUCCESS_MESSAGE, payload: message });

      history.push('/company/list');
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

// put
export const updateCompany = (id, data, history) => dispatch => {
  const company = {
    company_name: data.company_name,
    company_code: data.company_code,
    description: data.description,
    logo: data.logo,
    logo_square: data.logo_square,
    header_image: data.header_image,
  };

  axios
    .post(`${COMPANY_BE_URL}/${id}/update`, company)
    .then(res => {
      const { message } = res.data;

      dispatch({ type: SEND_SUCCESS_MESSAGE, payload: message });

      history.push('/company/list');
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

//delete

export const deleteCompany = id => dispatch => {
  axios
    .get(`${COMPANY_BE_URL}/${id}/delete`)
    .then(res => {
      dispatch({ type: DELETE_COMPANY_BY_ID, payload: id });
      const { message } = res.data;
      dispatch({ type: SEND_SUCCESS_MESSAGE, payload: message });

      // history.push('/company/list');
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

// remove
export const removeAllCompany = () => dispatch => {
  dispatch({ type: REMOVE_ALL_COMPANY });
};

export const removeCompany = () => dispatch => {
  dispatch({ type: REMOVE_COMPANY });
};

export const removeCompanyToOption = () => dispatch => {
  dispatch({ type: REMOVE_COMPANY_TO_OPTION });
};
