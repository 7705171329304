import {
  GET_ALL_TARGET,
  GET_TARGET_BY_ID,
  GET_TARGET_ACHIEVED_COUNT,
  GET_CUSTOMER_TURNOVER,
  REMOVE_ALL_TARGET,
  REMOVE_TARGET,
  REMOVE_CUSTOMER_TURNOVER,
} from '../actions/actionTypes';

const initialState = {
  targets: { loaded: false, data: [], totalRows: 0 },
  target: { loaded: false, data: {} },
  customerTurnovers: { loaded: false, data: [] },
  targetAchieved: {
    loaded: false,
    data: [],
    total: 0,
    short_journey: 0,
    long_journey: 0,
    prize: 0,
    super_prize: 0,
  },
};

const targetReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_TARGET:
      return {
        ...state,
        targets: {
          loaded: true,
          data: payload.targets,
          totalRows: payload.total_rows,
        },
      };
    case GET_TARGET_BY_ID:
      return { ...state, target: { loaded: true, data: payload } };
    case GET_CUSTOMER_TURNOVER:
      return { ...state, customerTurnovers: { loaded: true, data: payload } };
    case GET_TARGET_ACHIEVED_COUNT:
      return {
        ...state,
        targetAchieved: {
          loaded: true,
          data: payload.targets,
          total: payload.total,
          short_journey: payload.short_journey,
          long_journey: payload.long_journey,
          prize: payload.prize,
          super_prize: payload.super_prize,
        },
      };

    case REMOVE_ALL_TARGET:
      return { ...state, targets: { loaded: false, data: [], totalRows: 0 } };
    case REMOVE_TARGET:
      return { ...state, target: { loaded: false, data: {} } };
    case REMOVE_CUSTOMER_TURNOVER:
      return { ...state, customerTurnovers: { loaded: false, data: [] } };

    default:
      return state;
  }
};

export default targetReducer;
