import React, { Component } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { TextField, InputLabel, Select, MenuItem } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { redirectUser, resetPassword } from '../../actions/authAction';
import { getAllUserCode, removeAllUserCode } from '../../actions/boUserAction';

class ResetPassword extends Component {
  state = { data: [], isRequested: false };

  componentDidMount() {
    const token = this.props.match.params.token;

    this.props.getAllUserCode(token);
  }

  static getDerivedStateFromProps(props, state) {
    const {
      boUsers: { userCodes },
    } = props;

    if (userCodes.loaded && state.data.length <= 0) {
      const boUsers = userCodes.data.map(user => user.code);
      return { data: boUsers };
    }

    return null;
  }

  componentWillUnmount() {
    this.props.removeAllUserCode();
  }

  render() {
    const { intl } = this.props;
    const { data, isRequested } = this.state;
    const token = this.props.match.params.token;
    if (isRequested) {
      // this.props.redirectUser(this.props);
      return (
        // <Redirect
        //   to={{
        //     pathname: '/auth',
        //     state: 'Your password have been changed',
        //   }}
        // />
        <div className="kt-login__title">
          <h3>
            <FormattedMessage id="AUTH.FORGOT.PASSWORD.SUCCESS" />
          </h3>
        </div>
      );
    }

    return (
      <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <h3>
                <FormattedMessage id="AUTH.FORGOT.TITLE" />
              </h3>
            </div>

            <Formik
              initialValues={{ code: '', password: '', confirmPassword: '' }}
              validate={values => {
                const { code, password, confirmPassword } = values;
                const errors = {};

                const validPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+._]).{8,}$/.test(
                  password
                );

                if (!code) {
                  errors.code = intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                  });
                }
                if (!password) {
                  errors.password = intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                  });
                } else if (!validPassword) {
                  errors.password = intl.formatMessage({
                    id: 'AUTH.VALIDATION.PASSWORD_VALIDATION',
                  });
                }
                if (!confirmPassword) {
                  errors.confirmPassword = intl.formatMessage({
                    id: 'AUTH.VALIDATION.REQUIRED_FIELD',
                  });
                } else if (confirmPassword !== password) {
                  errors.confirmPassword = intl.formatMessage({
                    id: 'AUTH.VALIDATION.NOT.MATCHED',
                  });
                }

                return errors;
              }}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                resetPassword(values.code, values.password, token)
                  .then(res => {
                    this.setState({ isRequested: true });
                  })
                  .catch(err => {
                    setSubmitting(false);
                    setStatus(
                      intl.formatMessage({
                        id: 'AUTH.VALIDATION.PASSWORD_VALIDATION',
                      })
                    );
                  });
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} className="kt-form">
                  {status && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  )}

                  <div className="form-group">
                    <InputLabel id="selec-label">
                      <FormattedMessage id="AUTH.FORGOT.INPUT.CODE" />
                    </InputLabel>
                    <Select
                      labelId="select-label"
                      style={{ width: '100%' }}
                      name="code"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.code}
                      helpertext={touched.code && errors.code}
                      error={Boolean(touched.code && errors.code)}
                    >
                      {data.map((code, key) => (
                        <MenuItem key={key} value={code}>
                          {code}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                  <div className="form-group">
                    <TextField
                      type="password"
                      margin="normal"
                      label="Password"
                      className="kt-width-full"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      helperText={touched.password && errors.password}
                      error={Boolean(touched.password && errors.password)}
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      type="password"
                      margin="normal"
                      label="Conferma Password"
                      className="kt-width-full"
                      name="confirmPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.confirmPassword}
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                    />
                  </div>

                  <div className="kt-login__actions">
                    <button
                      type="submit"
                      className="btn btn-primary btn-elevate kt-login__btn-primary"
                      disabled={isSubmitting}
                    >
                      <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  boUsers: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  boUsers: state.app.boUser,
});

export default injectIntl(
  connect(mapStateToProps, { getAllUserCode, redirectUser, removeAllUserCode })(
    ResetPassword
  )
);
