import {
  GET_ALL_COMPANY,
  GET_COMPANY_BY_ID,
  SET_COMPANY_TO_OPTION,
  REMOVE_ALL_COMPANY,
  REMOVE_COMPANY,
  REMOVE_COMPANY_TO_OPTION,
  DELETE_COMPANY_BY_ID,
} from '../actions/actionTypes';

const initialState = {
  companies: { loaded: false, data: [] },
  company: { loaded: false, data: {} },
  companyToOption: { loaded: false, data: [] },
};

const companyReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_COMPANY:
      return { ...state, companies: { loaded: true, data: payload } };
    case GET_COMPANY_BY_ID:
      return { ...state, company: { loaded: true, data: payload } };
    case SET_COMPANY_TO_OPTION:
      return { ...state, companyToOption: { loaded: true, data: payload } };
    case DELETE_COMPANY_BY_ID:
      let companies = state.companies.data.filter(c => payload !== c._id);
      return { ...state, companies: { loaded: false, data: companies } };

    case REMOVE_ALL_COMPANY:
      return { ...state, companies: { loaded: false, data: [] } };
    case REMOVE_COMPANY:
      return { ...state, company: { loaded: false, data: {} } };
    case REMOVE_COMPANY_TO_OPTION:
      return { ...state, companyToOption: { loaded: false, data: [] } };

    default:
      return state;
  }
};

export default companyReducer;
