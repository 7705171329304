import { GET_ERRORS, REMOVE_ERRORS, REMOVE_MESSAGE } from './actionTypes';

export const dispatchError = message => dispatch => {
  dispatch({ type: GET_ERRORS, payload: message });
};

export const removeError = () => dispatch => {
  dispatch({ type: REMOVE_ERRORS });
};

export const removeMessage = () => dispatch => {
  dispatch({ type: REMOVE_MESSAGE });
};
