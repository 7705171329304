import { GET_ALL_APP, REMOVE_ALL_APP } from '../actions/actionTypes';

const initialState = {
  apps: { loaded: false, data: [] },
};

const appReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_APP:
      return { ...state, apps: { loaded: true, data: payload } };

    case REMOVE_ALL_APP:
      return { ...state, apps: { loaded: false, data: [] } };

    default:
      return state;
  }
};

export default appReducer;
