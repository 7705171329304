// 'admin',
// 'company_manager',
// 'area_manager',
// 'site_manager',
// 'agent',
// 'user',

const manipulateOwnDocument = ({ documentId = 'user', ownerId = 'owner' }) => {
  return documentId === ownerId;
};

const rules = {
  agent: {
    static: [
      'dashboard-page:visit',
      'app-user:list',
      'target:list',
      'target:view',
      'promo:list',
      'promo:view',
      'promo:company_report',
      'promo:agent_report',
      'turnover:list',
    ],
    dynamic: {
      'posts:edit': data => manipulateOwnDocument(data),
    },
  },
  site_manager: {
    static: [
      'dashboard-page:visit',
      'bouser:list',
      'bouser:create',
      'bouser:edit',
      'app-user:list',
      'survey:list',
      'survey:create',
      'survey:edit',
      'survey:view',
      'target:list',
      'target:view',
      'promo:list',
      'promo:view',
      'promo:company_report',
      'promo:agent_report',
      'news:list',
      'news:create',
      'news:edit',
      'luigi:list',
      'luigi:create',
      'luigi:edit',
      'turnover:list',
    ],
    dynamic: {
      'posts:edit': data => manipulateOwnDocument(data),
    },
  },
  area_manager: {
    static: [
      'dashboard-page:visit',
      'bouser:list',
      'bouser:create',
      'bouser:edit',
      'app-user:list',
      'survey:list',
      'survey:create',
      'survey:edit',
      'survey:view',
      'target:list',
      'target:view',
      'promo:list',
      'promo:view',
      'promo:company_report',
      'promo:agent_report',
      'news:list',
      'news:create',
      'news:edit',
      'luigi:list',
      'luigi:create',
      'luigi:edit',
      'turnover:list',
    ],
    dynamic: {
      'posts:edit': data => manipulateOwnDocument(data),
    },
  },
  company_manager: {
    static: [
      'dashboard-page:visit',
      'bouser:list',
      'bouser:create',
      'bouser:edit',
      'app-user:list',
      'survey:list',
      'survey:create',
      'survey:edit',
      'survey:view',
      'target:list',
      'target:view',
      'promo:list',
      'promo:view',
      'promo:company_report',
      'promo:agent_report',
      'news:list',
      'news:create',
      'news:edit',
      'luigi:list',
      'luigi:create',
      'luigi:edit',
      'turnover:list',
    ],
    dynamic: {
      'posts:edit': data => manipulateOwnDocument(data),
    },
  },
  admin: {
    static: [
      'dashboard-page:visit',
      'company:list',
      'company:create',
      'company:edit',
      'location:list',
      'location:create',
      'location:edit',
      'group:list',
      'group:create',
      'group:edit',
      'bouser:list',
      'bouser:create',
      'bouser:edit',
      'app:visit',
      'app-user:list',
      'app-user:create',
      'app-user:edit',
      'tile:list',
      'tile:create',
      'tile:edit',
      'journey:list',
      'journey:create',
      'journey:edit',
      'survey:list',
      'survey:create',
      'survey:edit',
      'survey:view',
      'question:list',
      'question:create',
      'question:edit',
      'target:list',
      'target:view',
      'email-template:list',
      'email-template:create',
      'email-template:edit',
      'custom-email-template:create',
      'custom-email-template:edit',
      'custom-email-template:list',
      'custom-push-template:edit',
      'custom-push-template:create',
      'custom-push-template:list',
      'user-category:list',
      'user-category:create',
      'promo:list',
      'promo:view',
      'promo:create',
      'promo:edit',
      'promo:company_report',
      'promo:agent_report',
      'award_option:create',
      'award_option:list',
      'award_option:edit',
      'news:list',
      'news:create',
      'news:edit',
      'luigi:list',
      'luigi:create',
      'luigi:edit',
      'turnover:list',
      'csv:list',
      'turnover:list',
      'user-sales:list',
      'user-sales:view',
    ],
  },
};

export default rules;
