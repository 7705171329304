import { orderBy } from 'lodash';

export const customSort = (rows, field, direction) => {
  const handleField = row => {
    if (row[field]) {
      return String(row[field]).toLowerCase();
    }

    return row[field];
  };

  return orderBy(rows, handleField, direction);
};

export const sortDirection = direction => {
  let sortDir = 1;

  switch (direction) {
    case 'asc':
      sortDir = 1;
      break;
    case 'desc':
      sortDir = -1;
      break;
    default:
      sortDir = 1;
      break;
  }

  return sortDir;
};
