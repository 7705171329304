import {
  GET_ALL_BO_USER,
  GET_BO_USER_BY_ID,
  GET_ALL_USER_CODE,
  SET_AGENTS_TO_OPTION,
  SET_AREA_MANAGER_TO_OPTION,
  REMOVE_ALL_BO_USER,
  REMOVE_BO_USER,
  REMOVE_ALL_USER_CODE,
  REMOVE_BO_USER_TO_OPTION,
  REMOVE_BO_USER_BY_ID,
} from '../actions/actionTypes';

const initialState = {
  boUsers: { loaded: false, data: [], totalRows: 0 },
  boUser: { loaded: false, data: {} },
  userCodes: { loaded: false, data: [] },
  // exclusively used for selection
  boUserToOption: {
    loaded: false,
    data: [],
    selectedCompany: '',
    selectedLocation: [],
  },
};

const boUserReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_BO_USER:
      return {
        ...state,
        boUsers: {
          loaded: true,
          data: payload.bousers,
          totalRows: payload.total_rows,
        },
      };
    case GET_BO_USER_BY_ID:
      return { ...state, boUser: { loaded: true, data: payload } };
    case GET_ALL_USER_CODE:
      return { ...state, userCodes: { loaded: true, data: payload } };
    case SET_AGENTS_TO_OPTION:
      return {
        ...state,
        boUserToOption: {
          loaded: true,
          data: payload.agent,
          selectedCompany: payload.company_code,
          selectedLocation: payload.location_code,
        },
      };
    case SET_AREA_MANAGER_TO_OPTION:
      return {
        ...state,
        boUserToOption: {
          loaded: true,
          data: payload.areaManager,
          selectedCompany: payload.company_code,
          selectedLocation: [],
        },
      };

    case REMOVE_ALL_BO_USER:
      return { ...state, boUsers: { loaded: false, data: [], totalRows: 0 } };
    case REMOVE_BO_USER:
      return { ...state, boUser: { loaded: false, data: {} } };
    case REMOVE_ALL_USER_CODE:
      return { ...state, userCodes: { loaded: false, data: {} } };
    case REMOVE_BO_USER_TO_OPTION:
      return {
        ...state,
        boUserToOption: {
          loaded: false,
          data: {},
          selectedCompany: '',
          selectedLocation: [],
        },
      };
    case REMOVE_BO_USER_BY_ID:
      let boUser = state.boUsers.data.filter(user => payload !== user._id);
      return {
        ...state,
        boUsers: {
          loaded: true,
          data: boUser,
          totalRows: state.boUsers.totalRows - 1,
        },
      };
    default:
      return state;
  }
};

export default boUserReducer;
